import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useFoolsData25 } from './useFoolsData25';

const Fools25Context = createContext(null);

export const Fools25Provider = ({ children }) => {
  const data = useFoolsData25();
  return <Fools25Context.Provider value={data}>{children}</Fools25Context.Provider>;
};

export const useFools25 = () => {
  return useContext(Fools25Context);
};

Fools25Context.propTypes = {
  festiveId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
