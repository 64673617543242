import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useValentineData25 } from './useValentineData25';



const Valentine25Context = createContext(null);

export const Valentine25Provider = ({children }) => {
  const data = useValentineData25();
  return <Valentine25Context.Provider value={data}>{children}</Valentine25Context.Provider>;
};

export const useValentine25 = () => {
  return useContext(Valentine25Context);
};

Valentine25Context.propTypes = {
  festiveId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
