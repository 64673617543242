// State
import { exportableStore as store } from '../state/store';

export const paths = {
  home: '/',
  stockGifts: '/stock/gifts/:rewardIdx/:stockCode',
  stockConditions: '/stock/conditions/:conditionIdx/:stockCode',
  activateCode: '/activateCode',
  contacts: '/contacts',
  order: '/order',
  profile: '/profile',
  constructorAlias: '/constructor/:id',
  constructorAliasNotId: '/constructor',
  halves: '/halves',
  signin: '/signin',
  signup: '/signup',
  registerForm: '?registerForm=open',
  address: '/address',
  recoveryPassword: '/recovery-password',
  restaurantsList: '/restaurants',
  restaurant: '/restaurants/:alias',
  stock: '/stock',
  stockAlias: '/stock/:alias',
  orderTrackingAlias: '/order-tracking/:alias',
  comboboxAlias: '/combo-box/:alias',
  // category: '/category/:name',
  productAlias: '/products/:category/:slug',
  papabonus: '/papabonus',
  nutrition: '/nutrition',
  nutritionAlias: '/nutrition/:alias',
  chatMobile: '/chat',
  sitemap: '/sitemap',
  brand: '/our-brand',
  advent: '/advent',
  adventShop: '/advent-shop',
  adventRedirect: '/advent-calendar',
  promoGender: '/game',
  okko: '/okko',
  districtsList: '/district',
  district: '/district/:district',
  deletemyaccount: '/deletemyaccount',
  catchRedirect: '/r/*',
  robots: '/robots.txt',
  superpapa: '/superpapa',
  halloween: '/halloween',
  valentine25: '/valentine25',
  foolsDay25: '/1april',
  hockey25: '/ovechkinrecord',
};

export const getRoute = (pathname, ...args) => {
  const { _supertag } = store.getState().city.userCity;

  // если ничего не передали, то редиректим на главную
  if (!pathname || pathname === '/') {
    return `/${_supertag}`;
  }

  // если урл имеет ведущий слеш, то подрезаем его
  const pathnameWithoutLeadingSlash = pathname[0] === '/' ? pathname.slice(1) : pathname;

  // если на главной добавляем квери параметры
  if (pathnameWithoutLeadingSlash[0] === '?') {
    return `/${_supertag}${pathnameWithoutLeadingSlash}`;
  }

  let i = 0;
  const newPath = pathnameWithoutLeadingSlash
    .split('/')
    .map(partOfPathname => {
      let newResult = partOfPathname;

      // если путь содержит алиасы :alias/:idx, то вместо них подставляем аргументы
      if (newResult[0] === ':' && args[i] !== undefined) {
        newResult = args[i];
        i += 1;
      }

      return newResult;
    })
    .join('/');

  // добавляем супертег
  return `/${_supertag}/${newPath}`;
};
