import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useAdventData25 } from './useAdventData25';



const AdventContext = createContext(null);

export const AdventProvider25 = ({children }) => {
  const data = useAdventData25();
  return <AdventContext.Provider value={data}>{children}</AdventContext.Provider>;
};

export const useAdvent25 = () => {
  return useContext(AdventContext);
};

AdventProvider25.propTypes = {
  festiveId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
