import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

// Components
import Container from '../../components/Container';
import { BackToMain } from '../../components/Okko/BackToMain/BackToMain';
import Heading from '../../components/Heading';
import BorderedFrame from './components/BorderedFrame';
import Button from '../../components/Button';
import HeartBlock, { heartImages } from './components/HeartBlock';
import LoginButton from '../Home/PromoGame/LoginModalPromoGame/LoginButton';

// Utils
import { getRoute, paths } from '../../entry/routes';
import { copyImgToClipboard } from './utils/copyImage';

// Hooks
import { useValentine25 } from './hooks/useValentine25';

// Styles
import styles from './Valentine25.styl';


const cx = classNames.bind(styles);

const Valentine25 = () => {

  const intl = useIntl();

  const history = useHistory();


  const { gender, isCompleted, isAuthenticated, heartType } = useValentine25();

  const translates = useRef({
    title: intl.formatMessage({ id: 'promo.valentine25.title' }),
    subtitle: intl.formatMessage({ id: 'promo.valentine25.subtitle' }),
    card1: intl.formatMessage({ id: 'promo.valentine25.card1' }, {
      profile: chunk => (<Link to={getRoute(paths.profile)}>
        {chunk}
      </Link>),
      home: chunk => (<Link to={getRoute(paths.home)}>
        {chunk}
      </Link>),
    }),
    card2: intl.formatMessage({ id: 'promo.valentine25.card2' }),
    card3: intl.formatMessage({ id: 'promo.valentine25.card3' }),
    button_game: intl.formatMessage({ id: 'promo.valentine25.button' }),
    button_gender: intl.formatMessage({ id: 'promo.valentine25.button.gender' }),
    button_completed: intl.formatMessage({ id: 'promo.valentine25.button.completed' }),
    button_auth: intl.formatMessage({ id: 'promo.advent.modal.auth.btn' }),
  }).current;


  const goTo = useCallback((path) => {
    history.push(getRoute(path));
  }, []);


  const getButtonProps = useCallback(() => {
    if (!isAuthenticated) {
      return {
        text: translates.button_auth,
        cb: () => window.scrollTo(0, 0), // Действие внутри компонента LoginButton
        component: LoginButton,
      };
    }

    if (!gender) {
      return {
        text: translates.button_gender,
        cb: () => goTo(paths.profile),
        component: Button,
      };
    }

    if (isCompleted) {
      return {
        text: translates.button_completed,
        cb: async () => {
          await copyImgToClipboard(heartImages[heartType].full);
        },
        component: Button,
      };
    }

    return {
      text: translates.button_game,
      cb: () => goTo(paths.home),
      component: Button,
    };
  }, [isAuthenticated, gender, isCompleted, goTo]);

  const { text, cb, component: Component } = useMemo(getButtonProps, [
    getButtonProps,
  ]);

  const contRef = useRef(null);

  useEffect(() => {
    if (contRef && contRef.current) {
      setTimeout(() => {
        contRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, []);

  return (
    <div ref={contRef} className={cx(styles.Valentine25)}>
      <Container size="m">
        <BackToMain />
        <Heading as="h1" className={cx(styles.Valentine25__title)}>
          {translates.title}
        </Heading>
        <Heading as="h3" className={cx(styles.Valentine25__subtitle)}>
          {translates.subtitle}
        </Heading>
        <div className={styles.Valentine25__grid}>
          <BorderedFrame className={cx(styles.Valentine25__card, styles.Valentine25__card_decor_1)}>

            <div className={styles.Valentine25__card_small}>{translates.card1}</div>
          </BorderedFrame>
          <BorderedFrame className={cx(styles.Valentine25__card, styles.Valentine25__card_big)}>
            {translates.card2}
            <HeartBlock gender={gender} isCompleted={isCompleted} heartType={heartType} />
          </BorderedFrame>
          <BorderedFrame className={cx(styles.Valentine25__card, styles.Valentine25__card_decor_3)}>
            <div
              className={styles.Valentine25__card_small}
              dangerouslySetInnerHTML={{ __html: translates.card3 }}
            />
          </BorderedFrame>
        </div>
      </Container>
      <Component className={styles.Valentine25__button} onClick={cb}>
        {text}
      </Component>
    </div>
  );
};

export default Valentine25;
