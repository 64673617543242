import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkActiveFestive, closeGamePopup } from '../../../state/modules/promotionGame/actions';

export const useFestiveEffect = () => {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const promoGameProperties = useSelector(state => state.promotionGame);
  const dispatch = useDispatch();

  const {
    common: {
      successAction,
    },
  } = promoGameProperties;


  useEffect(() => {
    if (successAction && successAction?.type === 'festive') {
      if (isAuthenticated) {
        dispatch(checkActiveFestive());
      } else {
        dispatch(closeGamePopup());
      }
    }
  }, [successAction, isAuthenticated]);
};
