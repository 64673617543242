import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { specialTheme } from '../state/modules/city/selectors';
import { themeNameSpaces } from '../state/modules/city/themeNameSpaces';
import { HalloweenProvider } from '../scenes/Halloween/useHalloween';
import PromoGameFestiveEffect from '../scenes/Home/PromoGame/PromoGameFestiveEffect';
import { AdventProvider25 } from '../scenes/Advent25/hooks/useAdvent25';

const PromoThemeProviders = ({ children }) => {
  const theme = useSelector(specialTheme);

  const getProviderProps = (theme) => {
    switch (theme) {
      case themeNameSpaces.HALLOWEEN:
        return {};
      default:
        return {};
    }
  };

  const themeProviders = {
    [themeNameSpaces.HALLOWEEN]: HalloweenProvider,
    [themeNameSpaces.NEW_YEAR_25]: AdventProvider25,
    [themeNameSpaces.KY]: PromoGameFestiveEffect,
  };
  const SelectedProvider = useMemo(() => themeProviders[theme] || React.Fragment, [theme]) ;

  const providerProps = getProviderProps(theme);

  return <SelectedProvider {...providerProps}>{children}</SelectedProvider>;
};

export default PromoThemeProviders;
