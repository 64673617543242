import React, { useEffect, useMemo, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import qs from 'query-string';
import HalalIcon from '../../../icons/halalMain.svg';

// Components
import AddressButton from '../../AddressButton/DesktopView';
import AuthModule from '../../AuthModule';
import LangSwitcher from '../../LangSwitcher/DesktopView';
import Nav from '../../Nav';
import Container from '../../Container';
import Logo from '../../Logo';
import CitySelector from '../../CitySelector';

// Icons
import CrossIcon from '../../../icons/cross.svg';
// Router
import { getRoute, paths } from '../../../entry/routes';

// Styles
import styles from './Header.styl';
import ModalCityRedirect from '../../ModalCityRedirect/MobileView/ModalCityRedirect';
import ModalAddress from '../../ModalAddress/DesktopView/ModalAddress';
import useOutsideClick from '../../../utils/useOutsideClick';
import { isAstronautTheme } from '../../../state/modules/city/selectors';

const cx = classNames.bind(styles);

export default function DesktopView(props) {
  const { className, isLanding } = props;
  const isAstronaut = useSelector(isAstronautTheme);
  const city = useSelector(state => state.city.userCity);

  const location = useLocation();
  const isOpen = useSelector(state => state.ui.isAuthPopupOpen);
  const [isOpenAuthModalParams, setIsOpenAuthModalParams] = useState(false);
  const [isMainNavOpen, setIsMainNavOpen] = useState(false);

  const isHome = matchPath(location.pathname, { path: getRoute(paths.home), exact: true });
  const registerForm = useMemo(() => {
    return qs.parse(location.search, { parseNumbers: true }).registerForm;
  }, [location.search]);

  const onMainNavToggle = () => {
    setIsMainNavOpen(prev => !prev);
  };

  useEffect(() => {
    setIsOpenAuthModalParams(registerForm === 'open');
  }, [location]);

  useEffect(() => {
    if (isOpen) {
      setIsMainNavOpen(true);
    }
  }, [isOpen]);

  const [isModalAddressPopupOpen, setIsModalAddressPopupOpen] = useState(false);

  const handleOpen = () => {
    if (!isOpen) {
      setIsModalAddressPopupOpen(true);
    }
  };

  const handleClose = () => {
    if (isOpen) {
      setIsModalAddressPopupOpen(false);
    }
  };

  const nodeRef = useOutsideClick(handleClose, isModalAddressPopupOpen);
  const isHalal = !!city?.is_halal;

  return (
    <header className={cx('Header', className, isAstronaut && 'Header_cosmos')} id="Header">
      <Container className={cx('Header__container')}>
        <Link
          className={cx(
            'Header__logo',
            (!isLanding || (isLanding && isMainNavOpen)) && 'Header__logo_regular',
          )}
          to={getRoute(paths.home)}
        >
          <Logo />
        </Link>
        {isMainNavOpen || isOpenAuthModalParams ? (
          <>
            <Nav view="desktop" className={cx('Header__Nav')} />
            <AuthModule registerForm={registerForm} />
          </>
        ) : (
          <>
            {!isLanding ? (
              <AddressButton />
            ) : (
              <CitySelector className={cx('Header__city-selector')} isLanding={isLanding} />
            )}
            {isHalal && (
              <div className={cx('Header__address-section')}>
                <HalalIcon width={50} height={50} />
              </div>
            )}
            <LangSwitcher className={cx('Header__LangSwitcher')} />
          </>
        )}

        {!isLanding && (
          <button className={cx('Header__hamburger')} onClick={onMainNavToggle} type="button">
            {isMainNavOpen ? (
              <CrossIcon />
            ) : (
              <>
                <span className={cx('Header__hamburger-line')} />
                <span className={cx('Header__hamburger-line')} />
                <span className={cx('Header__hamburger-line')} />
              </>
            )}
          </button>
        )}
        <ModalCityRedirect />
        <div ref={nodeRef}>
          <ModalAddress onOpen={handleOpen} />
        </div>
      </Container>
    </header>
  );
}

DesktopView.defaultProps = {
  className: '',
  isLanding: false,
};

DesktopView.propTypes = {
  className: PropTypes.string,
  isLanding: PropTypes.bool,
};
