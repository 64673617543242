import { toast } from 'react-toastify';

export async function downloadImage(imgUrl) {
  const link = document.createElement('a');
  link.href = imgUrl;
  link.download = 'image.png';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function copyImgToClipboard(imgUrl) {
  try {
    const data = await fetch(imgUrl);
    const blob = await data.blob();
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob,
        }),
      ]);
      toast.success(`Изображение скопировано`);
    } catch (error) {
      console.error(error);
      try {
        await downloadImage(imgUrl);
        toast.success(`Изображение сохранено`);
      } catch (e) {
        toast.info('Не удалось скопировать');
      }
    }
  } catch (err) {
  }
}
