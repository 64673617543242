import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { isThemeNewYear24Demo } from '../../state/modules/city/selectors';
import adventApi from './api';
import { updateProfile } from '../../state/modules/user/actions';
import { setGlobalMessage } from '../../utils/setGlobalMessage';
import { getRoute, paths } from '../../entry/routes';
import { getFormattedDate } from './utils';

export const adventModalTypes = {
  form: 'form',
  task: 'task',
  taskClosed: 'taskClosed',
  auth: 'auth',
  qr: 'qr',
  happy: 'happy',
  expired: 'expired',
};

export const adventTabs = {
  calendar: 'calendar',
  task: 'tasks',
  tickets: 'tickets',
};

export const adventCalendarTaskStatus = {
  complete: 'completed',
  active: 'active',
  disabled: 'expired',
  upcoming: 'disabled',
};

export const adventTaskType = {
  click: 'click',
  promocode: 'promocode',
  birthDate: 'birth_date',
  link: 'link',
  coupon: 'coupon',
  email: 'email',
  offline: 'offline',
  description: 'description',
};

const tilesSettings = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
  { id: 9 },
  { id: 10 },
  { id: 11 },
  { id: 12 },
  { id: 13 },
  { id: 14 },
  { id: 15 },
  { id: 16 },
  { id: 17 },
];

const tokenParam = 'oftb64';
const tokenValue = 'aGFwcHkgbmV3IHllYXIgZnJvbSBwaiBkZXZlbG9wZXJz';
export const useAdventData = () => {
  const intl = useIntl();
  const history = useHistory();
  const { isAuthenticated } = useSelector(state => state.user);
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const isNewYear24Demo = useSelector(isThemeNewYear24Demo);
  const location = useLocation();

  // isLoading
  const [isReady, setIsReady] = useState(true);

  // Tabs
  const [selectedAdventPageTab, setSelectedAdventPageTab] = useState(adventTabs.calendar);

  // TASKS
  const [tasks, setTasks] = useState([]);

  // Modal
  const [isModalShown, setIsModalShown] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [taskId, setTaskId] = useState(null);

  const closeModal = useCallback(() => {
    setIsModalShown(false);
  }, [setIsModalShown]);

  const showHappyModal = useCallback(
    id => {
      if (id) setTaskId(id);
      setIsModalShown(true);
      setModalType(adventModalTypes.happy);
    },
    [setIsModalShown, setModalType],
  );

  const showAuthModal = useCallback(() => {
    setIsModalShown(true);
    setModalType(adventModalTypes.auth);
  }, [setIsModalShown, setModalType]);

  const showSubscribeModal = useCallback(() => {
    setIsModalShown(true);
    setModalType(adventModalTypes.form);
  }, [setIsModalShown, setModalType]);

  const showQrModal = useCallback(() => {
    setIsModalShown(true);
    setModalType(adventModalTypes.qr);
  }, [setIsModalShown, setModalType]);

  // TRANSLATES
  const taskButtonTexts = useRef({
    [adventTaskType.link]: intl.formatMessage({ id: 'promo.advent.task.copy' }),
    [adventTaskType.click]: intl.formatMessage({ id: 'promo.advent.task.complete' }),
    [adventTaskType.coupon]: intl.formatMessage({ id: 'promo.advent.task.complete' }),
    [adventTaskType.email]: intl.formatMessage({ id: 'promo.advent.task.email' }),
    [adventTaskType.birthDate]: intl.formatMessage({ id: 'promo.advent.task.birthDate' }),
    [adventTaskType.offline]: intl.formatMessage({ id: 'promo.advent.modal.task.btn' }),
    [adventTaskType.description]: intl.formatMessage({ id: 'promo.advent.modal.task.btn' }),
  }).current;

  const successCopyMessage = useRef({
    [adventTaskType.link]: intl.formatMessage({ id: 'message.copy' }),
    [adventTaskType.coupon]: intl.formatMessage({ id: 'promoGame.popup.copy' }),
    default: intl.formatMessage({ id: 'message.copy' }),
  }).current;

  // CALLBACKS

  const completeTaskHandler = useCallback(
    async (id, taskPosition) => {
      // Выполняем задания только если не демо режим
      if (!isNewYear24Demo) {
        try {
          const response = await adventApi.taskCheckpoint(id);
          // @TODO проверить что в респонсе и засетать
          setTasks(prevState =>
            prevState.map(task =>
              task.realId !== id
                ? task
                : {
                    ...task,
                    status: adventCalendarTaskStatus.complete,
                    promocode: task.type === adventTaskType.coupon && response?.coupon,
                    reward: response,
                  },
            ),
          );
          showHappyModal(taskPosition);
        } finally {
        }
      }
    },
    [showHappyModal, setTasks, isNewYear24Demo],
  );

  const subscribeTaskHandler = useCallback(
    async (id, taskPosition) => {
      try {
        await dispatch(updateProfile({ subscription_state: true }));
        await completeTaskHandler(id, taskPosition);
      } finally {
      }
    },
    [tasks, dispatch, completeTaskHandler],
  );

  const onCopyHandler = useCallback(
    async (text, successMsg = successCopyMessage.default) => {
      await navigator.clipboard.writeText(text);
      setGlobalMessage(successMsg, { type: 'success' });
    },
    [setGlobalMessage, successCopyMessage],
  );

  const goToProfileHandler = useCallback(() => {
    history.push(getRoute(paths.profile));
  }, [history]);

  const goToExternalLink = useCallback(link => {
    window.open(link, '_blank', 'noopener,noreferrer');
  }, []);

  const goToTasksTab = useCallback(() => {
    setSelectedAdventPageTab(adventTabs.task);
    window.scrollTo(0, 0);
  }, [setSelectedAdventPageTab]);

  const goToTicketsTab = useCallback(() => {
    setSelectedAdventPageTab(adventTabs.tickets);
    window.scrollTo(0, 0);
  }, [setSelectedAdventPageTab]);

  const getCallBack = useCallback(
    (taskType, id, promocode, link, taskPosition) => {
      switch (taskType) {
        case adventTaskType.click:
          return completeTaskHandler(id, taskPosition);
        case adventTaskType.email:
          return subscribeTaskHandler(id, taskPosition);
        case adventTaskType.link:
          return onCopyHandler(link);
        case adventTaskType.promocode:
          return onCopyHandler(promocode, successCopyMessage.coupon);
        case adventTaskType.birthDate:
          return goToProfileHandler();
        case adventTaskType.coupon:
          return completeTaskHandler(id, taskPosition);
        case adventTaskType.offline:
          return goToExternalLink(link);
        case adventTaskType.description:
          return goToExternalLink(link);
        default:
          return null;
      }
    },
    [successCopyMessage],
  );

  // ВЫПОЛНЕНИЕ ЗАДАНИЯ ПО ССЫЛКЕ С ТОКЕНОМ
  useEffect(() => {
    const isOfflineTask = qs.parse(location.search)[tokenParam] === tokenValue;
    if (isOfflineTask && !isAuthenticated) {
      showQrModal();
    }
    if (tasks && isOfflineTask && isAuthenticated) {
      const offlineTask = tasks.find(task => task.type === adventTaskType.offline);
      if (offlineTask && offlineTask.status === adventCalendarTaskStatus.active) {
        completeTaskHandler(offlineTask.realId, offlineTask.id).finally(() => {
          history.replace({ search: '' });
        });
      }
    }
  }, [showQrModal, setIsReady, history, tasks, qs, location]);

  // Выполнение заданий подписки и даты рождения при входе на страницу
  useEffect(() => {
    if (isAuthenticated && tasks) {
      const bDateTask = tasks.find(task => task.type === adventTaskType.birthDate);
      const subscribeTask = tasks.find(task => task.type === adventTaskType.email);
      if (
        bDateTask &&
        bDateTask.status === adventCalendarTaskStatus.active &&
        profile?.birth_date
      ) {
        setTaskId(bDateTask.realId);
        completeTaskHandler(bDateTask.realId, bDateTask.id);
      }
      if (
        subscribeTask &&
        subscribeTask.status === adventCalendarTaskStatus.active &&
        profile?.subscription_state
      ) {
        setTaskId(subscribeTask.realId);
        completeTaskHandler(subscribeTask.realId, subscribeTask.id);
      }
    }
  }, [isAuthenticated, tasks, profile]);

  // Показ модального окна
  useEffect(() => {
    if (isNewYear24Demo) {
      showSubscribeModal();
    }
    if (!isNewYear24Demo && !isAuthenticated) {
      showAuthModal();
    }
  }, [isNewYear24Demo, showSubscribeModal, showAuthModal, isAuthenticated]);

  // Эффект для убирания активного задания для подскроливания к нужному заданию на табе ЗАДАНИЙ
  useEffect(() => {
    let timeoutId;
    if (!isModalShown) {
      timeoutId = setTimeout(() => setTaskId(null), 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isModalShown, setTaskId]);

  const getTaskDescription = useCallback((date, status, description) => {
    const taskClosedText = intl.formatMessage(
      { id: 'promo.advent.modal.taskClosed.text' },
      { content: `<b>${date}</b>` },
    );
    const taskExpiredText = intl.formatMessage({ id: 'promo.advent.modal.taskExpired.text' });

    if (status === adventCalendarTaskStatus.active) {
      return description;
    }
    if (status === adventCalendarTaskStatus.upcoming) {
      return taskClosedText;
    }

    if (status === adventCalendarTaskStatus.disabled) {
      return taskExpiredText;
    }
    return description;
  }, []);
  // ЗАПОЛНЯЕМ задания описаниями полученными с бэка
  useEffect(() => {
    const fetchData = async () => {
      await setIsReady(false);

      try {
        // @TODO ХАРДКОД номера адвент календаря
        const fetchedTasks = await adventApi.getAdventTasks(1).then(data => data);
        const updatedTiles = tilesSettings.map((task, index) => {
          const taskAvailableDate = getFormattedDate(
            fetchedTasks[index]?.date ?? '2023-12-31T05:00:00.000',
          );
          const taskClosedText = intl.formatMessage(
            { id: 'promo.advent.modal.taskClosed.text' },
            { content: `<b>точно до ${taskAvailableDate}</b>` },
          );

          const isTaskActiveOrComplete =
            fetchedTasks[index]?.status === adventCalendarTaskStatus.active ||
            fetchedTasks[index]?.status === adventCalendarTaskStatus.complete;
          if (fetchedTasks[index]) {
            return {
              ...fetchedTasks[index],
              id: task.id,
              realId: fetchedTasks[index].id,
              status: fetchedTasks[index].status ?? adventCalendarTaskStatus.upcoming,
              date: taskAvailableDate,
              type: fetchedTasks[index].type ?? adventTaskType.click,
              short_description: getTaskDescription(
                taskAvailableDate,
                fetchedTasks[index].status,
                fetchedTasks[index].short_description,
              ),
              description: getTaskDescription(
                taskAvailableDate,
                fetchedTasks[index].status,
                fetchedTasks[index].description,
              ),
              promocode: isTaskActiveOrComplete ? fetchedTasks[index].promocode : '',
              link: isTaskActiveOrComplete ? fetchedTasks[index].link : '',
              tickets: task.tickets ?? [],
              cb: () =>
                getCallBack(
                  fetchedTasks[index].type,
                  fetchedTasks[index].id,
                  fetchedTasks[index].promocode,
                  fetchedTasks[index].link,
                  task.id,
                ),
              btnText: isTaskActiveOrComplete ? taskButtonTexts[fetchedTasks[index].type] : '',
            };
          }
          return {
            id: task.id,
            realId: task.id,
            status: adventCalendarTaskStatus.upcoming,
            date: taskAvailableDate,
            type: adventTaskType.click,
            short_description: taskClosedText,
            description: taskClosedText,
            promocode: '',
            link: '',
            tickets: [],
            cb: null,
            btnText: '',
          };
        });
        setTasks(updatedTiles);
      } finally {
        setIsReady(true);
      }
    };

    if (isAuthenticated) {
      fetchData().finally(() => {});
    }
    if (!isAuthenticated) {
      setTasks(tilesSettings);
      setSelectedAdventPageTab(adventTabs.calendar);
    }
  }, [setTasks, setIsReady, tilesSettings, isAuthenticated, adventApi]);

  const userRewards = useMemo(() => {
    if (tasks.length) {
      const completedTasks = tasks.filter(task => task?.reward);
      if (completedTasks.length) {
        return completedTasks
          .sort(t => t?.date)
          .map((task, idx) => ({
            ...task?.reward,
            id: idx,
            date:
              getFormattedDate(task?.reward?.date, {
                month: 'numeric',
                day: 'numeric',
              }) ?? '',
          }));
      }
    }
    return [];
  }, [tasks]);

  // Коллбэк для табы Календаря
  const taskClickHandler = useCallback(
    id => {
      const task = tasks.find(tile => tile.id === id);

      if (task?.status === adventCalendarTaskStatus.active) {
        setModalType(adventModalTypes.task);
      }
      if (task?.status === adventCalendarTaskStatus.complete) {
        setModalType(adventModalTypes.happy);
      }

      if (task?.status === adventCalendarTaskStatus.upcoming) {
        setModalType(adventModalTypes.taskClosed);
      }

      if (task?.status === adventCalendarTaskStatus.disabled) {
        setModalType(adventModalTypes.expired);
      }
      setTaskId(id);
      setIsModalShown(true);
    },
    [setIsModalShown, setModalType, setTaskId, tasks, isAuthenticated],
  );

  return {
    tiles: tasks,
    isModalShown,
    setIsModalShown,
    closeModal,
    taskId,
    setTaskId,
    modalType,
    setModalType,
    taskClickHandler,
    selectedAdventPageTab,
    setSelectedAdventPageTab,
    showAuthModal,
    showSubscribeModal,
    userRewards,
    isReady,
    goToTasksTab,
    goToTicketsTab,
  };
};
