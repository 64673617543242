import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { changeBonuses } from '../state/modules/user/actions';

const POINTS_BURNING_PERIOD = 14;
const POINTS_BURNING_PERIOD_BLACK = 11;

const isSuperPoint = (item) => {
  const { expiry_info, action_date } = item;
  const spendingDays = Math.floor((new Date(expiry_info[0].expire_date).valueOf() - new Date(action_date).valueOf()) / 86400000);

  return (
    spendingDays <=
    POINTS_BURNING_PERIOD
  );
};

const isBlackPoint = (item) => {
  const { expiry_info, action_date, price, action } = item;
  const spendingDays = Math.floor((new Date(expiry_info[0].expire_date).valueOf() - new Date(action_date).valueOf()) / 86400000);
  const condition = action === 'purchase' && price;

  return (
    spendingDays <=
    POINTS_BURNING_PERIOD_BLACK && condition
  );
};

export const filterWillBurnPoints = (history, points) => {
  if (history && points) {
    const data = JSON.parse(JSON.stringify(history));
    const reversedData = data?.reverse();
    const willBurnArray = [];
    const spentPoints = points ? points.spent + points.spent_extra : 0;
    let confirmedSumm = 0;
    let willBurnSumm = 0;
    let counter = 0;
    reversedData?.forEach((item) => {
        const { is_completed, points_delta, expiry_info } = item;

        if (points_delta > 0 && is_completed) {
          //если начисление
          confirmedSumm += points_delta;
        }

        if (confirmedSumm > spentPoints) {
          //если сумма начислений больше чем сумма списаний
          counter++;
          if (points_delta > 0 && is_completed) {
            if (counter === 1) {
              //если это первое начисление с которого списались баллы
              item.points_delta = confirmedSumm - spentPoints;
            }
            if (expiry_info && expiry_info.length > 0) {
              if (isBlackPoint(item)) {
                item.points_type = 'black_points';
              } else if (isSuperPoint(item)) {
                item.points_type = 'super_points';
              } else {
                item.points_type = 'papa_points';
              }
              item.expire_date = expiry_info[0].expire_date;
              item.points_delta = expiry_info[0].points_expirable_remain;
              willBurnSumm += expiry_info[0].points_expirable_remain;

              willBurnArray.push(item);
            }
          }
        }
      },
    );
    return {
      willBurn: willBurnArray,
      willBurnPoints: willBurnSumm,
    };
  }
};


export const getActualPointAmount = (points, compositionCart, sPDiscount = 0) => {

  let spentPoints = compositionCart?.reduce((accum, good) => {
    if (good.type === 'bonus') {
      return accum + (good.count * good.price);
    }
    return accum + 0;
  }, sPDiscount);

  const {  papa_points,  super_points, black_points } = points;
  let needMinusPoints = spentPoints;
  let actualPapaPoints = papa_points?.value ?? 0;
  let actualSuperPoints = super_points?.value ?? 0;
  let actualBlackCashback = black_points?.value ?? null;

  if (actualBlackCashback > 0 && needMinusPoints > 0) {
    const minusPoints = needMinusPoints > actualBlackCashback ? actualBlackCashback : needMinusPoints;

    actualBlackCashback -= minusPoints;
    needMinusPoints -= minusPoints;
  }
  if (actualSuperPoints > 0 && needMinusPoints > 0) {
    const minusPoints = needMinusPoints > actualSuperPoints ? actualSuperPoints : needMinusPoints;

    actualSuperPoints -= minusPoints;
    needMinusPoints -= minusPoints;
  }
  if (actualPapaPoints > 0 && needMinusPoints > 0) {
    const minusPoints = needMinusPoints > actualPapaPoints ? actualPapaPoints : needMinusPoints;

    actualPapaPoints -= minusPoints;
    needMinusPoints -= minusPoints;
  }
  return { papaBonus: actualPapaPoints, superBonus: actualSuperPoints, blackBonus: actualBlackCashback };
};

export const useRecalculatedBonuses = () => {
  const isAuthenticated = useSelector(state => {
    return state.user?.isAuthenticated;
  });
  const dispatch = useDispatch();
  const points = useSelector(state => state.user?.profile?.loyalty?.points) ?? { black_points: {} };

  const { composition: compositionCart, sailplay_discount: spDiscount } = useSelector(state => state.cart);
  const isBlackFridayOn = useSelector(state => state.user?.profile?.cashback?.isBlackFriday);

  const filteredBonuses = useMemo(() => {
    const { black_points: { value } } = points;
    if (!isBlackFridayOn && !value) {
      return { ...points, black_points: null };
    }
    return points;
  }, [points, isBlackFridayOn]);

  useEffect(() => {
    if (isAuthenticated && filteredBonuses) {
      const bonuses = getActualPointAmount(filteredBonuses, compositionCart, spDiscount);
      dispatch(changeBonuses(bonuses));
    }
  }, [compositionCart, filteredBonuses, spDiscount]);

};

