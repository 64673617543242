import art1 from './1.png';
import art2 from './2.png';
import art3 from './3.png';
import art4 from './4.png';
import art5 from './5.png';

export const kuArtefacts = {
  kuzya: art1,
  flower: art2,
  ded: art3,
  sq: art4,
  house: art5,
}
