import React from 'react';
import classNames from 'classnames/bind';

import { Link } from 'react-router-dom';
import { getRoute, paths } from '../../../entry/routes';

import styles from './styles.styl';


const cx = classNames.bind(styles);

const Valentine25Link = ({ className, ...props }) => {
  return (
    <Link
      to={getRoute(paths.valentine25)}
      className={cx(styles.Valentine25Link, className)}
      {...props}
    />
  );
};

export default Valentine25Link;
