const getConfig = (isNew2, isLocal) => {
  const backendApi = process.env.BACKEND_API;
  const mainApiUrl = isNew2 ? 'api.papajohns.ru' : 'staging-api.papajohns.ru';
  const ssrApiUrl = isLocal ? 'staging-api.papajohns.ru' : backendApi;
  const geoApiUrl = isNew2 ? 'staging-geo.papajohns.ru' : 'service-geo.papajohns.ru';
  const messageApiUrl = isNew2
    ? 'message.papajohns.ru/api/v1'
    : 'staging-message.papajohns.ru/api/v1';
  const storageApiUrl = isNew2
    ? 'api-facade.papajohns.ru/api/v1'
    : 'staging-api-facade.papajohns.ru/api/v1';
  const messageWsUrl = isNew2 ? 'issue.papajohns.ru/api/v1' : 'staging-issue.papajohns.ru/api/v1';
  const socketUrl = isNew2 ? 'issue.papajohns.ru' : 'staging-issue.papajohns.ru';

  return {
    credentials: 'include',
    // Api Url
    apiUrl: `https://${mainApiUrl}`,
    ssrUrl: `http://${ssrApiUrl}`,
    // geo url
    geoUrl: `https://${geoApiUrl}`,
    // WebSocket url
    wssUrl: `wss://${mainApiUrl}/`,
    // Message Url
    messageApiUrl: `https://${messageApiUrl}/`,
    // Storage Url
    storageApiUrl: `https://${storageApiUrl}/`,
    // Message WS url
    wssMessageUrl: `https://${messageWsUrl}/`,
    // socket url
    socketUrl: `https://${socketUrl}/`,
    // Юрлицо Рус/Англ
    legal: 'ru',
    // GTM id
    GTM: 'GTM-KNJ4SBR',
    // Default Lang
    lang: 'ru',
    langList: ['ru', 'en'],
    // Телефон
    phone: {
      format: '+. (...) ...-..-..',
      digitsCount: 12,
      firstNumbers: '+7',
    },
    yandexApiKey: 'e667f831-0acd-42eb-a5e6-232cfcc37acc',
    dadataToken: '0118f2c409466e208dd43c61b3250c6d14ccec5e',
    reCaptchaSiteKey: isNew2
      ? '6LcxmscUAAAAAPk4FoUHWX3X_VDMqaLI5ADPct9L'
      : '6Lcmm7EZAAAAAJL325FqPqga528TX6yUD8uII60L',
    merchantID: 'merchant.papajohns.ru.papa.papajohns',
    newRegistration: false,
  };
};

module.exports = getConfig;
