export const themeNameSpaces = {
  FOOLS_DAY: 'fools_day',
  HATIKO: 'hatiko',
  NEW_YEAR: 'new_year',
  NEW_YEAR_24: 'advent_calendar',
  NEW_YEAR_24_demo: 'demo_advent_calendar',
  NEW_YEAR_25: 'advent_calendar_25',
  HALLOWEEN: 'halloween123',
  HALLOWEEN_QUEST: 'halloween23',
  HALLOWEEN_24: 'halloween24',
  MASTER_MARGO: 'master_margo',
  GENDER: 'gender_holiday',
  ASTRONAUT: 'space_day',
  FOOTBALL: 'football',
  MOVIE_10: 'movie10',
  OKKO: 'okko',
  MOVIE_BORDER_LANDS: 'borderlands',
  SUPERPAPA: 'super_dad',
  PADDINGTON: 'paddington',
  KY: 'domovenok_kuzya',
};
