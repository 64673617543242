import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import adventApi from '../Advent/api';
import { isThemeHalloween2024 } from '../../state/modules/city/selectors';
import {
  activateGame,
  clearArtefacts,
  setAdditionalPromoProperties,
  setGameArtefactPositions,
} from '../../state/modules/promotionGame/actions';
import { themeNameSpaces } from '../../state/modules/city/themeNameSpaces';
import { artefacts as halloween24artefacts } from './assets/artefacts';
import { getRoute, paths } from '../../entry/routes';
import { toggleAuthPopup } from '../../state/modules/ui/actions';
import { HW_TASK_STATUSES } from './constants';

const artefactsOrdered = [
  halloween24artefacts.death,
  halloween24artefacts.pumpkin,
  halloween24artefacts.grob,
  halloween24artefacts.bat2,
  halloween24artefacts.bat,
  halloween24artefacts.girl,
  halloween24artefacts.skeleton,
];

const halloweenArtefactConfig = {
  defaultTruePositions: [
    { id: null, variants: [], imageIndex: 0 }, // 0- с косой++
    { id: 'coupon', variants: [], imageIndex: 1 }, // 1-тыква++
    { id: null, variants: ['button'], imageIndex: 2 }, // 2-гроб++
    { id: null, variants: [], imageIndex: 3 }, // 3-мышь2++
    { id: 'footer', variants: [], imageIndex: 4 }, // 4-мышь++
    { id: null, variants: [], imageIndex: 5 }, // 5-девочка++
    { id: null, variants: [], imageIndex: 6 }, // 6-скелет++
  ],
  positionVarints: ['sideLeft', 'side', 'button', 'bottom', 'top', 'top_outside'],
};

const EVENT_ID = 7;

const mock = [
  { img: artefactsOrdered[0], status: HW_TASK_STATUSES.INACTIVE },
  { img: artefactsOrdered[1], status: HW_TASK_STATUSES.INACTIVE },
  { img: artefactsOrdered[2], status: HW_TASK_STATUSES.INACTIVE },
  { img: artefactsOrdered[3], status: HW_TASK_STATUSES.INACTIVE },
  { img: artefactsOrdered[4], status: HW_TASK_STATUSES.INACTIVE },
  { img: artefactsOrdered[5], status: HW_TASK_STATUSES.INACTIVE },
  { img: artefactsOrdered[6], status: HW_TASK_STATUSES.INACTIVE },
];

const useHalloweenData = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isRealPhone = useSelector(state => state.responsive.isRealPhone);
  const { isAuthenticated } = useSelector(state => state.user);
  const { list = [] } = useSelector(state => state?.catalog?.products);
  const isHalloween2024Mode = useSelector(isThemeHalloween2024);
  const { artefacts } = useSelector(state => state.promotionGame);

  const progress = useRef(mock);

  const findLastActiveDay = useCallback(progressArray => {
    const reversed = [...progressArray].reverse();

    return (
      reversed.find(
        pr => pr.status === HW_TASK_STATUSES.ACTIVE || pr.status === HW_TASK_STATUSES.COMPLETED,
      ) ?? null
    );
  }, []);

  const currentTask = useMemo(() => {
    return findLastActiveDay(progress.current);
    // return progress.current[6];
  }, [progress.current, findLastActiveDay]);

  const [isPageReady, setIsReady] = useState(false);

  // ARTEFACT MODAL
  const [isModalTaskCompletedShown, setIsModalTaskCompletedShown] = useState(false);
  const closeArtefactModal = useCallback(() => {
    setIsModalTaskCompletedShown(false);
  }, [setIsModalTaskCompletedShown]);

  // NOT AUTHORIZED MODAL
  const [isNotAuthorizedModalOpen, setIsNotAuthorizedModalOpen] = useState(!isAuthenticated);
  const openNotAuthorizedModal = useCallback(() => {
    if (isAuthenticated) {
      return;
    }
    setIsNotAuthorizedModalOpen(true);
  }, [isAuthenticated]);
  const closeNotAuthorizedModal = useCallback(() => {
    if (isRealPhone) {
      history.push(getRoute(paths.signin));
    } else {
      dispatch(toggleAuthPopup(true));
    }
    setIsNotAuthorizedModalOpen(false);
  }, []);

  // COLLECTED BONUSES
  const totalCollectedReward = useMemo(() => {
    if (progress && progress.current) {
      return progress.current
        .filter(t => t.status === HW_TASK_STATUSES.COMPLETED)
        .reduce((a, task) => a + Number(task?.rewardCount ?? 0), 0);
    }
    return 0;
  }, [progress.current]);

  //
  const isPromoFinished = useMemo(() => {
    return (
      !progress.current.filter(
        pr => pr.status === HW_TASK_STATUSES.INACTIVE || pr.status === HW_TASK_STATUSES.ACTIVE,
      ).length && isPageReady
    );
  }, [currentTask, isPageReady]);

  // GET and Set use progress
  const getUserProgress = useCallback(async () => {
    if (!isAuthenticated) {
      return null;
    }
    try {
      setIsReady(false);
      const eventData = await adventApi.getAdventTasks(EVENT_ID);
      const newProgress = progress.current.map((day, idx) => ({ ...day, ...eventData[idx] }));
      progress.current = newProgress;
      return newProgress;
    } finally {
      setIsReady(true);
    }
  }, [isAuthenticated, setIsReady]);

  const initialLoading = useCallback(async () => {
    if (!isHalloween2024Mode) {
      return;
    }
    if (isHalloween2024Mode) {
      dispatch(activateGame(themeNameSpaces.HALLOWEEN_24));
      dispatch(setAdditionalPromoProperties({ successAction: null }));
    }
    try {
      if (isAuthenticated && list.length) {
        const eventData = await getUserProgress();

        if (!eventData.length) {
          throw new Error('No advent tasks found.');
        }

        const activeDay = findLastActiveDay(eventData);

        const isPromoStillActive = Boolean(
          [...eventData].filter(
            pr => pr.status === HW_TASK_STATUSES.INACTIVE || pr.status === HW_TASK_STATUSES.ACTIVE,
          ).length,
        );

        if (activeDay && isPromoStillActive) {
          const dayIndex = eventData.findIndex(d => d.status === HW_TASK_STATUSES.ACTIVE);
          if (dayIndex > -1) {
            dispatch(
              setGameArtefactPositions({
                catalog: list,
                gameConfig: {
                  ...halloweenArtefactConfig,
                  defaultTruePositions: [halloweenArtefactConfig.defaultTruePositions[dayIndex]],
                },
                initialApiCatalog: false,
              }),
            );
          }
        }
      }
    } catch (e) {
      throw new Error(e?.message);
    }
  }, [isAuthenticated, list, isHalloween2024Mode, getUserProgress]);

  useEffect(() => {
    initialLoading().finally(() => setIsReady(true));
  }, [initialLoading]);

  // EFFECT FOR ARTEFACT CHECKPOINT
  useEffect(() => {
    let timeoutId;

    if (
      artefacts.length &&
      artefacts[0].checked &&
      currentTask &&
      currentTask.status === HW_TASK_STATUSES.ACTIVE
    ) {
      (async () => {
        try {
          const taskCompleteData = await adventApi.taskCheckpoint(currentTask.id);

          // ЕСЛИ НАГРАДА НЕ ВЕРНУЛАСЬ ИЗ ЗАПРОСА ПРОХОЖДЕНИЯ, делаем новый запрос за прогрессом
          // или просто оставить ревалидацию в момент посещения страницы
          if (taskCompleteData && taskCompleteData.count) {
            progress.current = progress.current.map(day =>
              day.id === currentTask.id
                ? {
                    ...day,
                    rewardCount: taskCompleteData.count,
                    status: HW_TASK_STATUSES.COMPLETED,
                  }
                : day,
            );
          } else {
            await getUserProgress();
          }

          setIsModalTaskCompletedShown(true);

          timeoutId = setTimeout(() => dispatch(clearArtefacts()), 5000);
        } catch (e) {
          throw new Error(e?.message);
        }
      })();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [artefacts, setIsModalTaskCompletedShown, currentTask]);

  return {
    isAuthenticated,
    isNotAuthorizedModalOpen,
    openNotAuthorizedModal,
    closeNotAuthorizedModal,
    progress: progress.current,

    isReady: isPageReady,

    collectedReward: totalCollectedReward,

    revalidateProgress: getUserProgress,
    currentTask,

    isPromoFinished,

    artefactModal: {
      isModalOpen: isModalTaskCompletedShown,
      closeModal: closeArtefactModal,
    },
  };
};

export default useHalloweenData;
