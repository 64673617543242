/* eslint-disable no-param-reassign */
import qs from 'query-string';

// State
import { exportableStore as store } from '../state/store';

// Utils
import { setGlobalMessage } from './setGlobalMessage';
import { getAccessToken, removeAccessToken, removeUnauthorizedToken } from './auth';
import isomorphicCookies from './cookie/isomorphicCookies';

// Config
import config from '../config';

async function request({
  url,
  method = 'GET',
  body = null,
  headers = {},
  data = {},
  type = null,
  proxy = false,
  ssr = false,
}) {
  headers.Accept = 'application/json';

  if (type !== 'form-data') {
    headers['Content-Type'] = 'application/json';
  }

  const { isRealPhone, isRealTablet } = store.getState().responsive;
  data.platform = isRealPhone || isRealTablet ? 'web-mobile' : 'web';

  // получение куки срабатывает только на клиенте. на сервере нужно прокидывать в запрос вручную
  const access_token = getAccessToken();
  if (access_token) {
    data.access_token = access_token;
  }

  // получение куки срабатывает только на клиенте. на сервере нужно прокидывать в запрос вручную
  const city_id = isomorphicCookies().get('city_id');
  if (city_id) {
    data.city_id = city_id;
  }

  // получение куки срабатывает только на клиенте. на сервере нужно прокидывать в запрос вручную
  const lang = isomorphicCookies().get('locale');
  if (lang) {
    data.lang = lang;
  }

  // убираем подсказку в правом нижнем углу
  const withError = data.withError !== false;
  let query = '';

  if (type === 'form-data') {
    const form_data = new FormData();

    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        data[key].forEach(value => {
          form_data.append(`${key}[]`, value);
        });
      } else {
        form_data.append(key, data[key]);
      }
    });

    body = form_data;
  } else if (['GET', 'DELETE'].includes(method)) {
    // GET or DELETE
    query = `?${qs.stringify(data)}`;

    if (body) {
      body = JSON.stringify(body);
    }
  } else {
    // POST or PUT
    body = JSON.stringify(data);
  }

  const baseUrl = proxy ? `/` : ssr ? `${config.ssrUrl}/` : `${config.apiUrl}/`;
  const newUrl = baseUrl + url + query;

  try {
    const response = await fetch(newUrl, {
      method,
      body,
      headers,
      credentials: config.credentials || 'same-origin',
    });
    const json = await response.json();

    if (!response.ok) {
      if (response.status === 401) {
        removeAccessToken();
        removeUnauthorizedToken();
      } else if (json.message && withError) {
        if (typeof json.message === 'object') {
          Object.keys(json.message).map(key => setGlobalMessage(json.message[key]));
        } else if (typeof json.message === 'string') {
          const validMessage = json.message.replace(/<br>/gi, '');
          setGlobalMessage(validMessage);
        }
      }

      throw new Error(json.message || json?.contact[0] || 'Что-то пошло не так');
    }

    return json;
  } catch (e) {
    console.error(e.message);
    throw e;
  }
}

export const get = params => request({ method: 'GET', ...params });
export const post = params => request({ method: 'POST', ...params });
export const put = params => request({ method: 'PUT', ...params });
export const del = params => request({ method: 'DELETE', ...params });
export const patch = params => request({ method: 'PATCH', ...params });
