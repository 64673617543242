import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIntl } from 'react-intl';
import {
  adventCalendarTaskStatus,
  adventTaskType,
  useDuplicatedCUSTOMFestiveTasks,
} from './festive/useDuplicatedCUSTOMFestiveTasks';
import isomorphicCookies from '../../../utils/cookie/isomorphicCookies';

const COOKIE_FOOLS_NAME = 'f_d_25_t';
const WAITING_TIME_SECONDS = 59;

export const useFoolsData25 = () => {
  const intl = useIntl();

  const translates = useRef({
    congrats: intl.formatMessage({ id: 'promo.fools25.rewards.item.congrats' }),
    rewardPreviewCode: intl.formatMessage({ id: 'promo.fools25.rewards.item.code' }),
    rewardPreviewPoints: intl.formatMessage({ id: 'promo.fools25.rewards.item.bonuses' }),
  }).current;

  const { isAuthenticated } = useSelector(state => state.user);

  const { tiles, ...rest } = useDuplicatedCUSTOMFestiveTasks({ festiveId: 7, tasksCount: 0 });

  const [modalShownData, setModalShownData] = useState(null);

  const prepareModalData = ({
    date,
    promocode = null,
    description: text,
    short_description: imgLink,
  }) => {
    const prepared = {
      date,
      previewText: `${translates.congrats}<span>${
        !!promocode ? translates.rewardPreviewCode : translates.rewardPreviewPoints
      }</span>`,
      promocode,
      img: imgLink,
      text,
    };
    return { ...prepared, onClick: () => setModalShownData(prepared) };
  };

  const activeTasks = useMemo(() => {
    return tiles.filter(tile => tile.status === adventCalendarTaskStatus.active);
  });

  const isFinished = useMemo(() => activeTasks.length === 0, [activeTasks]);

  const [waitingTime, setWaitingTime] = useState(null);

  // Читаем куку при монтировании
  useEffect(() => {
    const cookieWaitingTime = Number(isomorphicCookies().get(COOKIE_FOOLS_NAME));

    if (!cookieWaitingTime || cookieWaitingTime < Date.now()) {
      isomorphicCookies().remove(COOKIE_FOOLS_NAME);
      setWaitingTime(0);
      return;
    }

    setWaitingTime(Math.floor((cookieWaitingTime - Date.now()) / 1000));
  }, []);

  // Таймер
  useEffect(() => {
    if (waitingTime <= 0) {
      return undefined;
    }

    const interval = setInterval(() => {
      setWaitingTime(prev => {
        if (prev <= 1) {
          clearInterval(interval);
          isomorphicCookies().remove(COOKIE_FOOLS_NAME);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [waitingTime]);

  const nextTaskToComplete = useMemo(
    () => tiles.find(tile => tile.status === adventCalendarTaskStatus.active),
    [tiles],
  );

  const setWaitForTime = useCallback(() => {
    const expirationTimestamp = Date.now() + WAITING_TIME_SECONDS * 1000;
    isomorphicCookies().set(COOKIE_FOOLS_NAME, expirationTimestamp, {
      expires: new Date(expirationTimestamp),
    });

    setWaitingTime(WAITING_TIME_SECONDS);
  }, []);

  const isButtonActive = useMemo(() => {
    return !!(waitingTime === 0 && !isFinished && nextTaskToComplete && nextTaskToComplete.cb);
  }, [waitingTime, isFinished, nextTaskToComplete]);

  const onButtonClick = useCallback(async () => {
    if (isButtonActive) {
      console.log('OK');
      try {
        await nextTaskToComplete.cb();
        setWaitForTime();
        setModalShownData(prepareModalData(nextTaskToComplete));
      } catch {
        return undefined;
      }
    }
    return undefined;
  }, [nextTaskToComplete, setWaitForTime, isButtonActive]);

  const foolsDayRewards = useMemo(() => {
    return tiles
      .filter(
        t => t.status === adventCalendarTaskStatus.complete && t.type !== adventTaskType.click,
      )
      .map(prepareModalData);
  }, [tiles]);

  const closeModal = useCallback(() => setModalShownData(null), [setModalShownData]);
  return {
    isAuthenticated,
    tiles,
    waitingTime,
    isFinished,
    onButtonClick,
    isButtonActive,
    foolsDayRewards,
    modalShownData,
    closeModal,
    ...rest,
  };
};
