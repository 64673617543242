import * as types from './actionTypes';

const initialState = {
  isPromotionGameActive: false,
  common: {
    isPopupShown: false,
    isPromoProgressFinished: false,
    artefactsTotalCount: 0,
    artefactsCheckedCount: 0,
    rewardCode: null,
    rewardString: null,
    isArtefactPreviouslyChecked: false,
    falsyArtefactMessageShow: false,
    isLoginPopupOpen: false,
    successAction: {
      type: 'coupon',
      festive: null, // или {id: number, taskId: number}
    },
    onlyForRegisteredUsers: false
  },
  artefacts: [
    // пример объекта
    // {
    //   id: number,
    //   checked: false,
    //   imageProps: {
    //     imageIndex: imageArray[index],
    //     positionVariant?: 'side' | 'button' | 'sideLeft'
    //   }
    // }
  ],
};

export default function promotionGame(state = initialState, { type, payload }) {
  switch (type) {
    case types.ACTIVATE_PROMOTION_GAME:
      return {
        ...state,
        isPromotionGameActive: true,
        gameName: payload.gameName,
      };

    case types.SET_ADDITIONAL_PROMOTION_PROPERTIES:
      return {
        ...state,
        common: {
          ...state.common,
          ...payload
        }
      };
    case types.SET_ARTEFACTS_COUNT:
      return {
        ...state,
        common: { ...state.common, artefactsTotalCount: payload.artefactsTotalCount },
      };

    case types.SET_ARTEFACTS_POSITIONS:
      return {
        ...state,
        artefacts: payload.positions,
      };
    case types.CLEAR_ARTEFACTS_POSITIONS:
      return {
        ...state,
        artefacts: [],
      };
    case types.PROMO_GAME_CHECKPOINT:
      return {
        ...state,
        common: {
          ...state.common,
          isArtefactPreviouslyChecked: false,
          artefactsCheckedCount: state.common.artefactsCheckedCount + 1,
          falsyArtefactMessageShow: false,
        },
        artefacts: state.artefacts.map(art =>
          art.id === payload.id ? { ...art, checked: true } : art,
        ),
      };
    case types.PROMO_GAME_CHECKPOINT_REPEATED:
      return {
        ...state,
        common: {
          ...state.common,
          isArtefactPreviouslyChecked: true,
          falsyArtefactMessageShow: false,
        },
      };
    case types.PROMO_GAME_FINISH_GAME:
      return {
        ...state,
        common: { ...state.common, isPromoProgressFinished: true },
      };
    case types.PROMO_GAME_REWARD_REQUEST_SUCCESS:
      return {
        ...state,
        common: { ...state.common, rewardCode: payload.rewardCode, isPromoProgressFinished: true },
      };

    case types.PROMO_GAME_SET_REWARD_STRING:
      return {
        ...state,
        common: { ...state.common, rewardString: payload.reward, isPromoProgressFinished: true },
      };

    case types.CLOSE_PROMO_GAME_POPUP:
      return {
        ...state,
        common: { ...state.common, isPopupShown: false },
      };

    case types.OPEN_PROMO_GAME_POPUP:
      return {
        ...state,
        common: { ...state.common, isPopupShown: true },
      };

    case types.PROMO_GAME_FALSY_ARTEFACT:
      return {
        ...state,
        common: { ...state.common, isPopupShown: true, falsyArtefactMessageShow: true },
      };
    case types.PROMO_GAME_OPEN_LOGIN_POPUP:
      return {
        ...state,
        common: { ...state.common, isLoginPopupOpen: true },
      };
    case types.PROMO_GAME_CLOSE_LOGIN_POPUP:
      return {
        ...state,
        common: { ...state.common, isLoginPopupOpen: false },
      }

    case types.PROMO_GAME_RESET_GAME:
      return {
        ...state,
        artefacts: state.artefacts.map(art => ({...art, checked: false})),
        common: {
          ...state.common,
          isPromoProgressFinished: false,
          // isPopupShown: false
          artefactsCheckedCount: 0
        }
      }
    default:
      return state;
  }
}
