import { createSelector } from 'reselect';
import { closedCities } from '../../../utils/constants';
import { themeNameSpaces } from './themeNameSpaces';

const cityId = {
  MOSCOW: 1,
};

export const isDiscountCashbackSystemSelector = createSelector(
  state => state.city.userCity.sailplay?.type,
  sailplayType => sailplayType === 'discount' || sailplayType === 'gifts_and_discount',
);

export const isGiftsCashbackSystemSelector = createSelector(
  state => state.city.userCity.sailplay?.type,
  sailplayType => sailplayType === 'gifts' || sailplayType === 'gifts_and_discount',
);

export const onlyMoscowCitiesSelector = createSelector(
  state => state.city.list,
  cities =>
    cities.filter(
      city => city.parent_id === cityId.MOSCOW && !closedCities.includes(city._supertag),
    ),
);

export const otherCitiesSelector = createSelector(
  state => state.city.list,
  cities =>
    cities.filter(
      city => city.parent_id !== cityId.MOSCOW && !closedCities.includes(city._supertag),
    ),
);

export const filteredCitiesByNameSelector = createSelector(
  [state => state.city.list, state => state.city.searchQuery],
  (cities, value) => {
    const query = value.toLowerCase();
    return cities.filter(city => city.name.slice(0, query.length).toLowerCase() === query);
  },
);

export const allLinksSelector = createSelector(
  state => state.city.userCity.links || [],
  links => links.filter(link => Boolean(link.url)),
);

export const socialLinksSelector = createSelector(allLinksSelector, links => {
  return links.filter(link => link.type === 'social');
});

export const applicationLinksSelector = createSelector(allLinksSelector, links => {
  return links.filter(link => link.type === 'application');
});

export const appStoreUrlSelector = createSelector(applicationLinksSelector, links => {
  return links.find(link => link.key === 'apple')?.url;
});

export const playMarketUrlSelector = createSelector(applicationLinksSelector, links => {
  return links.find(link => link.key === 'google')?.url;
});

export const appGalleryUrlSelector = createSelector(applicationLinksSelector, links => {
  return links.find(link => link.key === 'huawei')?.url;
});

export const specialTheme = createSelector(
  state => state?.city?.userCity?.theme,
  theme => (theme ? theme?.trim() : ''),
);

export const isSpecialTheme = createSelector(
  state => state?.city?.userCity?.theme,
  theme => Boolean(theme ? theme?.trim() : ''),
);

export const isThemeHatiko = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.HATIKO,
);

export const isThemeNewYear = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.NEW_YEAR,
);

export const isThemeNewYear24 = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.NEW_YEAR_24 || theme === themeNameSpaces.NEW_YEAR_24_demo,
);

export const isThemeNewYear25 = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.NEW_YEAR_25,
);

export const isThemeNewYear24Demo = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.NEW_YEAR_24_demo,
);

export const isThemeHalloween = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.HALLOWEEN,
);

export const isThemeHalloween2024 = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.HALLOWEEN_24,
);

export const isThemeHalloweenQuest = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.HALLOWEEN,
);

export const isMaterAndMargo = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.MASTER_MARGO,
);

export const isGenderPromo = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.GENDER,
);

export const isOkko = createSelector(specialTheme, theme => theme === themeNameSpaces.OKKO);

export const isAstronautTheme = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.ASTRONAUT,
);

export const isFootBallTheme = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.FOOTBALL,
);

export const isMovie10Theme = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.MOVIE_10,
);

export const isMovieBorderTheme = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.MOVIE_BORDER_LANDS,
);

export const isMoviePaddingtonTheme = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.PADDINGTON,
);

export const isSuperpapaTheme = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.SUPERPAPA,
);

export const isValentine25Theme = createSelector(
  specialTheme,
  theme => theme === themeNameSpaces.VALENTINE25,
);
