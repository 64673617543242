import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useHockeyData25 } from './useHockeyData25';

const Hockey25Context = createContext(null);

export const Hockey25Provider = ({ children }) => {
  const data = useHockeyData25();
  return <Hockey25Context.Provider value={data}>{children}</Hockey25Context.Provider>;
};

export const useHockey25 = () => {
  return useContext(Hockey25Context);
};

Hockey25Context.propTypes = {
  festiveId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};
