import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.styl';

import left1 from '../assets/heart/1l.png';
import right1 from '../assets/heart/1r.png';
import full1 from '../assets/heart/1full.png';
import left2 from '../assets/heart/2l.png';
import right2 from '../assets/heart/2r.png';
import full2 from '../assets/heart/2full.png';
import left3 from '../assets/heart/3l.png';
import right3 from '../assets/heart/3r.png';
import full3 from '../assets/heart/3full.png';
import empty from '../assets/heart/empty.png';

export const heartImages = {
  1: {
    left: left1,
    right: right1,
    full: full1,
  },
  2: {
    left: left2,
    right: right2,
    full: full2,
  },
  3: {
    left: left3,
    right: right3,
    full: full3,
  },
};


const cx = classNames.bind(styles);
const HeartBlock = ({ className, gender = null, isCompleted = false, heartType = null, ...props }) => {

  const heartHalves = useMemo(() => {
    if (!heartType) {
      return {
        '--full': `url('${empty}')`,
      };
    }
    const imgStyles = {
      '--left': `url('${heartImages[heartType].left}')`,
      '--right': `url('${heartImages[heartType].right}')`,
      '--full': `url('${heartImages[heartType].full}')`,
    };

    if (!isCompleted) {
      styles['--full'] = `url('${empty}')`;
      if (gender === 'male') {
        imgStyles['--right'] = 'none';
      } else {
        imgStyles['--left'] = 'none';
      }
    }

    return imgStyles;
  }, [gender, isCompleted, heartType]);

  return (
    <div className={cx(styles.Heart, className)} {...props}>
      <div
        className={cx(styles.Heart__inner, (isCompleted || !gender) && styles.Heart__inner_full)}
        style={heartHalves ?? {}}
      >
      </div>
    </div>
  );
};

export default HeartBlock;
