import React from 'react';
import BackIcon from '../../../icons/backWhite.svg';
import styles from './BackToMain.styl';
import classNames from 'classnames/bind';
import { getRoute, paths } from '../../../entry/routes';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

const cx = classNames.bind(styles);

export const BackToMain = ({ className = '' }) => {
  const intl = useIntl();

  const history = useHistory();
  const back = () => {
    history.push(getRoute(paths.home));
  };
  return (
    <div className={cx(styles.container, className)}
         onClick={back}>
      <div className={cx(styles.icon)}>
        <BackIcon />
      </div>
      <div className={cx(styles.text)}>
        {intl.formatMessage({ id: 'page.okko.back' })}
      </div>
    </div>
  );
};

