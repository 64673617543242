import * as types from './actionTypes';
import { getRoute, paths } from '../../../entry/routes';

const initialState = {
  isHomeCategoriesStick: false,
  isHomeCatalogVisible: true,
  isAlcoholWarningOpen: true,
  isAdultGoodsBlocked: true,
  isAdultGoodsModalShown: false,
  isCityPopupOpen: false,
  isAuthPopupOpen: false,
  cityToRedirect: null,
  footerLinks: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: '',
  },
  openedProductCardId: null,
};

export default function catalogReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_FOOTER_LINKS_REQUEST:
      return {
        ...state,
        footerLinks: {
          ...state.footerLinks,
          isLoading: true,
          isLoaded: false,
          error: '',
        },
      };

    case types.FETCH_FOOTER_LINKS_SUCCESS:
      return {
        ...state,
        footerLinks: {
          ...state.footerLinks,
          list: [...action.payload],
          isLoading: false,
          isLoaded: true,
          error: '',
        },
      };

    case types.FETCH_FOOTER_LINKS_FAILURE:
      return {
        ...state,
        footerLinks: {
          ...state.footerLinks,
          isLoading: false,
          isLoaded: false,
          error: action.payload,
        },
      };

    case types.TOGGLE_HOME_CATALOG:
      return {
        ...state,
        isHomeCatalogVisible: action.payload,
      };

    case types.TOGGLE_HOME_CATEGORIES:
      return {
        ...state,
        isHomeCategoriesStick: action.payload,
      };

    case types.TOOGLE_ALCOHOL_WARNING:
      return {
        ...state,
        isAlcoholWarningOpen: !state.isAlcoholWarningOpen,
      };

    case types.DISABLE_ADULT_GOODS:
      return {
        ...state,
        isAdultGoodsBlocked: false,
      };

    case types.ENABLE_ADULT_GOODS:
      return {
        ...state,
        isAdultGoodsBlocked: true,
      };

    case types.TOGGLE_ADULT_GOODS_POPUP:
      return {
        ...state,
        isAdultGoodsModalShown: !state.isAdultGoodsModalShown,
      };
    case types.TOGGLE_CITY_POPUP:
      return {
        ...state,
        isCityPopupOpen: action.payload,
      };

    case types.TOGGLE_AUTH_POPUP:
      return {
        ...state,
        isAuthPopupOpen: action.payload,
      };

    case types.SET_CITY_REDIRECT:
      return {
        ...state,
        cityToRedirect: action.payload,
      };

    case types.CLEAR_CITY_REDIRECT:
      return {
        ...state,
        cityToRedirect: null,
      };
    case types.OPEN_PRODUCT_CARD:
      return {
        ...state,
        openedProductCardId: action.payload.id
      }

    default:
      return state;
  }
}
