import { useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFestiveTasks, adventCalendarTaskStatus, adventTaskType } from './festive/useFestiveTasks';




export const useAdventData25 = () => {
  const [taskId, setTaskId] = useState(null);
  const { isAuthenticated } = useSelector(state => state.user);
  const initialized = useRef(null);


  const { isReady, tiles, userRewards, ...rest } = useFestiveTasks(
    { festiveId: 5, tasksCount: 21 });


  // УСТАНАВЛИВАЕМ ID ПОСЛЕДНЕГО ДОСТУПНОГО ЗАДАНИЯ
  useEffect(() => {
    const last = tiles.findLast(t => t.status === adventCalendarTaskStatus.active || t.status === adventCalendarTaskStatus.complete);
    if (!!last && !initialized.current) {
      setTaskId(last.id);
      initialized.current = true;
    }
  }, [tiles, setTaskId]);


  const dividedTasks = useMemo(() => {
    const divided = {
      tasks: [],
      shop: [],
    }
    if (tiles.length) {
      tiles.forEach(tile => tile.type === adventTaskType.purchase ? divided.shop.push(tile) : divided.tasks.push(tile));
    }

    return divided;
  }, [tiles]);

  return {
    isAuthenticated,
    tiles: dividedTasks.tasks,
    taskId,
    setTaskId,
    userRewards,
    isReady,
    shopTasks: dividedTasks.shop,
    ...rest,
  };
};
