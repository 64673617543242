import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.styl';

const cx = classNames.bind(styles);

const BorderedFrame = ({ className, children, ...props }) => {
  return (
    <div className={cx(styles.BorderedFrame, className)} {...props}>
      {children}
    </div>
  );
};

export default BorderedFrame;
