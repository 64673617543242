import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import useHalloweenData from './useHalloweenData';

const HalloweenContext = createContext(null);

export const HalloweenProvider = ({ children }) => {
  const data = useHalloweenData();
  return <HalloweenContext.Provider value={data}>{children}</HalloweenContext.Provider>;
};

export const useHalloween = () => {
  return useContext(HalloweenContext);
};

HalloweenProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
