export const ymId = 4816108;

export const sendEventViewProduct = () => {
  try {
    window.ym(ymId, 'reachGoal', 'ViewProduct');
  } catch (e) {
    console.log(e);
  }
};

export const sendEventBannerView = idx => {
  try {
    window.ym(ymId, 'reachGoal', `banner_view_${idx}`);
  } catch (e) {
    console.log(e);
  }
};

export const sendEventBannerClick = idx => {
  try {
    window.ym(ymId, 'reachGoal', `banner_click_${idx}`);
  } catch (e) {
    console.log(e);
  }
};

export const sendEventAddProduct = () => {
  try {
    window.ym(ymId, 'reachGoal', 'AddProduct');
  } catch (e) {
    console.log(e);
  }
};

export const sendEventAddAddress = () => {
  try {
    window.ym(ymId, 'reachGoal', 'AddAddress');
  } catch (e) {
    console.log(e);
  }
};

export const sendEventOKAddress = () => {
  try {
    window.ym(ymId, 'reachGoal', 'OKAddress');
  } catch (e) {
    console.log(e);
  }
};

export const sendEventPurchasePromocode = () => {
  try {
    window.ym(ymId, 'reachGoal', 'PurchasePromocode');
  } catch (e) {
    console.log(e);
  }
};

export const sendCustomYEvent = event => {
  try {
    window.ym(ymId, 'reachGoal', event);
  } catch (e) {
    console.log(e);
  }
};
