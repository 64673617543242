import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
  adventTaskType,
  adventCalendarTaskStatus,
  useFestiveTasks,
} from '../../Advent25/hooks/festive/useFestiveTasks';
import {
  activateGame, clearArtefacts, deactivateGame, setAdditionalPromoProperties,
  setGameArtefactPositions,
} from '../../../state/modules/promotionGame/actions';
import { themeNameSpaces } from '../../../state/modules/city/themeNameSpaces';
import { promotionConfigs } from '../../../state/modules/promotionGame/promotionConfigs';

export const gendersNamespaces = {
  NOT_SET: null,
  MALE: 'male',
  FEMALE: 'female',
};

const gendersMap = {
  0: gendersNamespaces.NOT_SET,
  1: gendersNamespaces.MALE,
  2: gendersNamespaces.FEMALE,
};

const valentine25ArtefactConfig = {
  defaultTruePositions: [
    { id: null, variants: [], imageIndex: 0 },
    { id: null, variants: [], imageIndex: 1 },
  ],
  positionVarints: ['sideLeft', 'side', 'button', 'bottom', 'top', 'top_outside'],
};

const CATEGORY = 'hot';

export const useValentineData25 = () => {

  const dispatch = useDispatch();
  const { isAuthenticated, profile } = useSelector(state => state.user);
  const catalog = useSelector(state => state.catalog.products.list);

  const { isPromotionGameActive, common } = useSelector(state => state.promotionGame);


  const { isReady, tiles, userRewards, ...rest } = useFestiveTasks(
    {
      festiveId: promotionConfigs[themeNameSpaces.VALENTINE25]?.common?.successAction?.festive?.id ?? 6,
      tasksCount: 0,
    });


  const [isCompleted, setIsCompleted] = useState(false);

  const heartType = useMemo(() => {
    return tiles.find(t => t.type === adventTaskType.gender)?.reward?.count;
  }, [tiles]);

  useEffect(() => {
    if (isAuthenticated && isReady && tiles.length > 1 && profile.gender && profile.gender !== 0 && catalog.length > 0 && heartType) {

      const { status: festiveTaskStatus, realId } = tiles[1] ?? { status: null };


      if (!festiveTaskStatus || !realId) {
        toast.warn('Мы не сможем подобрать тебе твою вторую половину. Это наша вина :(');
      }

      if (festiveTaskStatus === adventCalendarTaskStatus.complete) {
        setIsCompleted(true);
        return;
      }
      if (festiveTaskStatus === adventCalendarTaskStatus.disabled) {
        return;
      }

      if (festiveTaskStatus === adventCalendarTaskStatus.active) {

        const filteredCatalog = catalog.filter(g => g.category === CATEGORY);

        const imageIndexMap = {
          11: 1,
          12: 0,
          21: 3,
          22: 2,
          31: 5,
          32: 4,
        };

        dispatch(activateGame(themeNameSpaces.VALENTINE25));
        dispatch(setAdditionalPromoProperties({
          successAction: {
            type: 'festive',
            festive: null,
          },
        }));
        dispatch(
          setGameArtefactPositions({
            catalog: filteredCatalog.length > 0 ? [...filteredCatalog, ...filteredCatalog] : catalog,
            gameConfig: {
              ...valentine25ArtefactConfig,
              defaultTruePositions: [{
                id: null,
                variants: [],
                imageIndex: imageIndexMap[`${heartType}${profile.gender}`],
              }],
            },
            initialApiCatalog: false,
          }),
        );
      }

    } else {
      dispatch(deactivateGame());
      setIsCompleted(false);
    }
  }, [isReady, profile, catalog, isPromotionGameActive, isAuthenticated, heartType]);

  useEffect(() => {
    let timeout;
    if (isPromotionGameActive) {
      const { artefactsCheckedCount, artefactsTotalCount } = common;
      if (artefactsCheckedCount === artefactsTotalCount) {
        const taskToComplete = tiles.find(t => t.type === adventTaskType.click);

        if (taskToComplete && taskToComplete.cb && taskToComplete.status === adventCalendarTaskStatus.active && !isCompleted) {
          taskToComplete.cb();
          setIsCompleted(true);
        }
        timeout = setTimeout(() => dispatch(clearArtefacts()), 8000);
      }
    }
    return () => clearTimeout(timeout);
  }, [common, isPromotionGameActive, tiles, isCompleted, setIsCompleted]);


  return {
    isAuthenticated,
    tiles,
    isCompleted,
    gender: gendersMap[profile?.gender] ?? gendersNamespaces.NOT_SET,
    heartType,
    ...rest,
  };
};
