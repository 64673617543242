import * as types from './actionTypes';
import promoGameApi from './api';
import {setGlobalMessage} from "../../../utils/setGlobalMessage";


export function activateGame(gameName) {
  return {type: types.ACTIVATE_PROMOTION_GAME, payload: {gameName}};
}

export function setArtefactsTotalCount(artefactsTotalCount) {
  return {type: types.SET_ARTEFACTS_COUNT, payload: {artefactsTotalCount}}
}

export const clearArtefacts = () => {
  return {type: types.CLEAR_ARTEFACTS_POSITIONS}
}

export const setAdditionalPromoProperties = (payload) => {
  return {type: types.SET_ADDITIONAL_PROMOTION_PROPERTIES, payload}
}

export function setGameArtefactPositions({
                                           catalog,
                                           gameConfig: {
                                             artefactsCountToGenerate = 0,
                                             defaultTruePositions = [],
                                             defaultFalsyPositions = [],
                                             defaultPositionsFalsy,
                                             positionVarints,
                                             picturesCount,
                                             countDefaultInTotal,
                                             categoriesArtefacts,
                                           },
                                          initialApiCatalog = true
                                         }) {
  return dispatch => {
    const additionalArtefactsPositionCount = artefactsCountToGenerate + defaultTruePositions.length

    let filteredProductList = catalog

    if (initialApiCatalog){
      filteredProductList = catalog.reduce((list, cat) => ([...list, ...cat.goods]), [])
    }
    filteredProductList = filteredProductList
      .filter(good => !(good.alias === 'sozday' || good.alias === 'halves'));

    const getRandomArrayIndex = (array = []) => Math.floor(Math.random() * array.length)

    const imageIndexes = [...Array(artefactsCountToGenerate).keys()]

    const createArtefactObject = (id, variants = [], isFalsyArtefact = false, positionVariant, imageSpecificIndex) => {
      const index = getRandomArrayIndex(imageIndexes)

      const artefactObject = {
        id,
        checked: false,
        isFalsyArtefact,
        imageProps: {imageIndex: imageSpecificIndex ?? imageIndexes[index], positionVariant: positionVariant ?? positionVarints[getRandomArrayIndex(positionVarints)]}
        // imageProps: {imageIndex: imageSpecificIndex ?? imageIndexes[index], positionVariant: positionVariant ?? positionVarints[getRandomArrayIndex(positionVariant)]}
      }
      if (variants.length) {
        artefactObject.imageProps.positionVariant = variants[getRandomArrayIndex(variants)]
      }
      imageIndexes.splice(index, 1)
      return artefactObject
    }

    const goodsIndexesToBecomeArtefacts = new Set();

    // УМЫШЛЕННО ПОДГОТАВЛИВАЕМ ИНДЕКСОВ ТОВАРОВ БОЛЬШЕ НЕОБХОДИМОГО (на случай если у дефолтных товаров не будет индексов)
    while (goodsIndexesToBecomeArtefacts.size < additionalArtefactsPositionCount) {
      const newArtefactGoodIndex = getRandomArrayIndex(filteredProductList)
      if (newArtefactGoodIndex
        && filteredProductList[newArtefactGoodIndex]
        && filteredProductList[newArtefactGoodIndex]?.id
      ) {
        goodsIndexesToBecomeArtefacts.add(newArtefactGoodIndex)
      }
    }

    const addImages = [...goodsIndexesToBecomeArtefacts]

    const trueArtefact = [...Array(artefactsCountToGenerate).keys(), ...defaultTruePositions]

    const positions = []

    // ДОБАВЛЯЕМ ПРАВДИВЫЕ АРТЕФАКТЫ
    trueArtefact.forEach((idxOrPos, index) => idxOrPos instanceof Object
      // В СЛУЧАЕ ЕСЛИ У АРТЕФАКТА НЕТ ИНДЕКСА ВОЗЬМЕМ РАНДОМНЫЙ ТОВАР
      ? positions.push(createArtefactObject(idxOrPos.id ?? filteredProductList[addImages[index]].id, idxOrPos.variants, false, idxOrPos.id, idxOrPos.imageIndex))
      : positions.push(createArtefactObject(filteredProductList[addImages[index]].id, positionVarints)))

    // ДОБАВЛЯЕМ ЛОЖНЫЕ АРТЕФАКТЫ
    if (defaultFalsyPositions.length) {
      defaultFalsyPositions.forEach(pos => {
          const falsyArtefact =
            pos instanceof String ? createArtefactObject(pos) : createArtefactObject(pos.id, pos.variants, true, pos.id, pos.imageIndex)
          positions.push(falsyArtefact)
        }
      )
    }

    // ДОБАВЛЯЕМ АРТЕФАКТЫ ДЛЯ КАТЕГОРИЙ
    if (categoriesArtefacts) {
      const categoryAliases = [...new Set(catalog.map(good => good.alias))]
      categoryAliases.forEach(category => positions.push(createArtefactObject(category, [], categoriesArtefacts === 'falsy', category)))
    }

    const realArtefactsCount = positions.filter(art => !art.isFalsyArtefact).length

    dispatch(setArtefactsTotalCount(realArtefactsCount))
    dispatch({type: types.SET_ARTEFACTS_POSITIONS, payload: {positions}})
  }
}

export function openGamePopup() {
  return {type: types.OPEN_PROMO_GAME_POPUP};
}

export function closeGamePopup() {
  return {type: types.CLOSE_PROMO_GAME_POPUP};
}

export function gameCheckpoint(id) {
  return (dispatch, getState) => {

    const promoGameProperties = getState().promotionGame
    const isAlreadyChecked = promoGameProperties.artefacts.find(art => art.id === id).checked
    const {artefactsCheckedCount, artefactsTotalCount, rewardCode, successAction} = promoGameProperties.common

    const shouldGenerateCoupon = successAction === 'coupon'
    dispatch(openGamePopup())

    if (!isAlreadyChecked) {
      dispatch({type: types.PROMO_GAME_CHECKPOINT, payload: {id}});
    } else {
      dispatch({type: types.PROMO_GAME_CHECKPOINT_REPEATED});
      if (artefactsCheckedCount >= artefactsTotalCount && !rewardCode && shouldGenerateCoupon) {
        dispatch(getPromoCoupon())
      }
      return
    }
    if (artefactsCheckedCount + 1 >= artefactsTotalCount && !rewardCode && shouldGenerateCoupon) {
      dispatch(getPromoCoupon())
    }
  }
}

export function gameFalsyCheckpoint() {
  return (dispatch, getState) => {
    dispatch({type: types.PROMO_GAME_FALSY_ARTEFACT})

    dispatch(openGamePopup())
  }
}

export const getPromoCoupon = () => dispatch => {

  promoGameApi.getPromoCoupon()
    .then(data => dispatch(promoGameFinished(data.coupon)))
    .catch(() => {
      dispatch(closeGamePopup())
    })
}

export const promoGameFinished = (rewardCode) => {
  return {type: types.PROMO_GAME_REWARD_REQUEST_SUCCESS, payload: {rewardCode}}
}
