import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Styles
import { useSelector } from 'react-redux';
import styles from './FlagsDecor.styl';
import { themeNameSpaces } from '../../../../state/modules/city/themeNameSpaces';
import { isThemeNewYear24, isThemeNewYear25 } from '../../../../state/modules/city/selectors';

const cx = classNames.bind(styles);

const FlagsDecor = ({ className, bigFlags }) => {
  const { gameName } = useSelector(state => state.promotionGame);
  const isNewYear24 = useSelector(isThemeNewYear24);
  const isNewYear25 = useSelector(isThemeNewYear25);

  const isFoolsDayGame = useRef(gameName === themeNameSpaces.FOOLS_DAY).current;
  const isHalloweenGame = useRef(gameName === themeNameSpaces.HALLOWEEN_QUEST).current;

  return (
    <div
      className={cx(
        'FlagsDecor',
        {
          FlagsDecor_foolsDay_big: isFoolsDayGame && bigFlags,
          FlagsDecor_foolsDay: isFoolsDayGame,
          FlagsDecor_hw: isHalloweenGame && !bigFlags,
          FlagsDecor_ny_24: isNewYear24 || isNewYear25,
        },
        className,
      )}
    />
  );
};

export default FlagsDecor;

FlagsDecor.propTypes = {
  className: PropTypes.string,
  bigFlags: PropTypes.bool,
};

FlagsDecor.defaultProps = {
  className: '',
  bigFlags: false,
};
