import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { specialTheme } from '../state/modules/city/selectors';
import { themeNameSpaces } from '../state/modules/city/themeNameSpaces';
import { HalloweenProvider } from '../scenes/Halloween/useHalloween';
import PromoGameFestiveEffect from '../scenes/Home/PromoGame/PromoGameFestiveEffect';
import { AdventProvider25 } from '../scenes/Advent25/hooks/useAdvent25';
import { Valentine25Provider } from '../scenes/Valentine25';
import { Fools25Provider } from '../scenes/PromoFoolsDay25/hooks/useFools25';
import { Hockey25Provider } from '../scenes/PromoHockey/hooks/useHockey25';

const PromoThemeProviders = ({ children }) => {
  const theme = useSelector(specialTheme);

  const getProviderProps = theme => {
    switch (theme) {
      case themeNameSpaces.HALLOWEEN:
        return {};
      default:
        return {};
    }
  };

  const themeProviders = {
    [themeNameSpaces.HALLOWEEN]: HalloweenProvider,
    [themeNameSpaces.NEW_YEAR_25]: AdventProvider25,
    [themeNameSpaces.KY]: PromoGameFestiveEffect,
    [themeNameSpaces.VALENTINE25]: Valentine25Provider,
    [themeNameSpaces.FOOLS_DAY_25]: Fools25Provider,
  };
  const SelectedProvider = useMemo(() => themeProviders[theme] || React.Fragment, [theme]);

  const providerProps = getProviderProps(theme);

  return (
    <Hockey25Provider>
      <SelectedProvider {...providerProps}>{children}</SelectedProvider>
    </Hockey25Provider>
  );
};

export default PromoThemeProviders;
