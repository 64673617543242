import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route, useLocation, Redirect, matchPath, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import className from 'classnames';
import qs from 'query-string';

// Components
import Layout from '../components/Layout';
import Chat from '../components/Chat';
import { CityAdsScript } from '../components/_Scripts/CityAds/CityAdsScript';

// Scenes
import Error404 from '../scenes/Error404';

// Partials
import useRoutes from './useRoutes';

// State
import { updateLocation } from '../state/modules/location';
import { initApplePay } from '../state/modules/payTypes/actions';
import { fetchCartItems, setAllowedCartSailplayDiscount } from '../state/modules/cart/actions';
import { getLocalStorageCheckout } from '../state/modules/order/actions';
import { fetchFooterLinks } from '../state/modules/ui/actions';
import { fetchRestaurants } from '../state/modules/restaurant/actions';
import { getUnconditionalList } from '../state/modules/stock/actions';
import { fetchAddresses } from '../state/modules/address/actions';

// Utils
import useMediaQueryMatch from '../utils/useMediaQueryMatch';

// Styles
import '../styles/reset.styl';
import '../styles/main.css';
import '../styles/main.styl';
import { useRecalculatedBonuses } from '../utils/useRecalculatedBonuses';
import Providers from '../providers';
import { AdultModal } from '../components/AdultModal/AdultModal';

const default404 = {
  layout: {
    simple: false,
    theme: 'secondary',
  },
};

const findCurrentRoute = (routes, pathname) => {
  return (
    routes.find(route => matchPath(pathname, { path: route.path, exact: true })) || default404
  );
};

export default function App() {
  const intl = useIntl();
  const location = useLocation();
  const { title, help_number, image, _supertag, is_own } = useSelector(
    state => state.city.userCity,
  );
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const { routes } = useRoutes();

  useRecalculatedBonuses();

  const [currentRoute, setCurrentRoute] = useState(default404);

  useEffect(() => {
    setCurrentRoute(findCurrentRoute(routes, location.pathname));
  }, [location]);

  useEffect(() => {
    // возвращаем скролл наверх при переходе между страницами
    if (!currentRoute.layout.isScrollToTopDisabled) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, currentRoute]);

  return (
    <>
      <Layout {...currentRoute.layout}>
        <Helmet>
          <body className={className({ desktop: currentRoute.newest && !isRealPhone })} />
          <html lang={intl.locale} />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content={
              isRealPhone
                ? 'width=device-width, initial-scale=1.0, viewport-fit=cover, maximum-scale=1, user-scalable=no'
                : 'width=device-width, initial-scale=1.0, viewport-fit=cover'
            }
          />
          <title>{currentRoute.helmet?.title || title}</title>
          <meta
            name="description"
            content={
              currentRoute.helmet?.description ||
              `${title} | ${intl.formatMessage({ id: 'og.title' })}`
            }
          />
          <meta name="og:title" content={`${title} | ${intl.formatMessage({ id: 'og.title' })}`} />
          <meta name="og:description" content={intl.formatMessage({ id: 'og.description' })} />
          <meta name="og:image" content={image} />
          <meta name="og:phone_number" content={help_number} />
          <link rel="icon" type="image/png" sizes="32x32" href="/Favicon2022/favicon-32x32.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/Favicon2022/apple-touch-icon.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="application-name" content="Papa John's" />
          <meta name="yandex-verification" content="eb38f08d0d6d04cd" />
          <meta
            name="google-site-verification"
            content="V0LbsmTPVXVlbaxKgNEX-7NwRQytU2WNiMI5WKWwEqs"
          />
          {currentRoute.helmet?.other || null}
        </Helmet>
        <AppEffects />
        <Providers>
          <Switch>
            {routes.map(route => (
              <Route key={route.path} path={route.path} exact={route.exact || true}>
                {route.redirect
                  ? routeProps => {
                      if (routeProps.staticContext) {
                        routeProps.staticContext.statusCode = 301;
                      }

                      return (
                        <Redirect to={{ ...routeProps.location, pathname: `/${_supertag}` }} />
                      );
                    }
                  : route.children}
              </Route>
            ))}
            <Error404 />
          </Switch>
        </Providers>
      </Layout>
      {isAuthenticated && !isRealPhone && !!is_own && <Chat />}
      <CityAdsScript />
      <AdultModal />
    </>
  );
}

// вынес в отдельный компонент, чтобы основной App не ререндерился при изменениях
function AppEffects() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const cart = useSelector(state => state.cart);
  const currentRestaurant = useSelector(state => state.order.checkout.currentRestaurant);

  useEffect(() => {
    // запрашиваем акции и условия к ним (rewards: Получите скидку 25% на заказ, condition: Необходимо сделать заказ самовывозом)
    dispatch(getUnconditionalList());
    // Проверка возможности использования дополнительных форм оплаты
    dispatch(initApplePay());
    // достаем из локалстоража данные с чекаута
    dispatch(getLocalStorageCheckout());
    // запрашиваем линки для футера
    dispatch(fetchFooterLinks());
    // запрашиваем список всех ресторанов
    dispatch(fetchRestaurants());
  }, []);

  // запрашиваем список сохраненных адресов если авторизованы
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchAddresses());
    }
  }, [isAuthenticated]);

  // перезапрашиваем корзину при входе/выходе и при смене текущего ресторана, тк нужен delivery_area_id
  useEffect(() => {
    // для активации промокода через url /?code=20WOW
    const { code } = qs.parse(location.search);
    dispatch(fetchCartItems(code));

    // убираем из урла code
    if (code) {
      history.replace({ search: qs.exclude(location.search, ['code']) });
    }
  }, [isAuthenticated, currentRestaurant]);

  useEffect(() => {
    // для аналитики
    dispatch(updateLocation(location));
  }, [location.pathname]);

  // если в корзине изменилась итоговая цена или туда попали подарки за баллы
  // то надо пересчитать колво введенного кешбека
  useEffect(() => {
    dispatch(setAllowedCartSailplayDiscount());
  }, [cart]);

  useMediaQueryMatch();

  return null;
}
