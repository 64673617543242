import { useSelector } from 'react-redux';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  adventCalendarTaskStatus,
  adventTaskType,
  useFestiveTasks,
} from '../../Advent25/hooks/festive/useFestiveTasks';
import isomorphicCookies from '../../../utils/cookie';

const HOCKEY_WIN_NOT_AUTH_COOKIE_NAME = 'h_w_n_a';

export const useHockeyData25 = () => {
  const { isAuthenticated } = useSelector(state => state.user);
  const [isModalShown, setIsModalShown] = useState(false);

  const { isReady, tiles, ...rest } = useFestiveTasks({
    festiveId: 8,
    tasksCount: 0,
  });

  const taskToComplete = useMemo(
    // Затираем задание в случае если список был получен но пользователь вылогинился (проблемы нет, только для ui)
    () => (isAuthenticated ? tiles.reverse().find(t => t.type === adventTaskType.click) : null),
    [tiles, isAuthenticated],
  );

  const completeGameCb = useCallback(async () => {
    await isomorphicCookies().remove(HOCKEY_WIN_NOT_AUTH_COOKIE_NAME);

    if (
      taskToComplete &&
      taskToComplete.cb &&
      taskToComplete.status === adventCalendarTaskStatus.active
    ) {
      taskToComplete.cb();
      toast.success('Все отлично! Спеши за баллами в личный кабинет');
    } else if (taskToComplete.status === adventCalendarTaskStatus.complete) {
      toast.warn('Нельзя начислить баллы повторно');
    } else {
      toast.warn('Это задание уже неактивно');
    }
  }, [taskToComplete]);

  const saveGameCookie = useCallback(() => {
    isomorphicCookies().set(HOCKEY_WIN_NOT_AUTH_COOKIE_NAME, 1, { expires: 1 });
    toast.warn('Авторизуйся на сайте, и мы начислим тебе выигрыш');
    setIsModalShown(true);
  }, [setIsModalShown]);

  const checkCookieForWin = useCallback(async () => {
    const isCookieExist = isomorphicCookies().get(HOCKEY_WIN_NOT_AUTH_COOKIE_NAME);
    if (isCookieExist && isAuthenticated && taskToComplete) {
      console.log('EXIST');
      await completeGameCb();
    }
  }, [completeGameCb, isAuthenticated, taskToComplete]);

  useEffect(() => {
    if (isAuthenticated) {
      checkCookieForWin();
    }
  }, [isAuthenticated, checkCookieForWin]);

  const closeModal = useCallback(() => setIsModalShown(false), [setIsModalShown]);
  return {
    isAuthenticated,
    completeGameCb,
    saveGameCookie,
    checkCookieForWin,
    isModalShown,
    closeModal,
    ...rest,
  };
};
