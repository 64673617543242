const prefix = '@promotionGame';

export const ACTIVATE_PROMOTION_GAME = `${prefix}/ACTIVATE_PROMOTION_GAME`;
export const DEACTIVATE_PROMOTION_GAME = `${prefix}/DEACTIVATE_PROMOTION_GAME`;

export const SET_ADDITIONAL_PROMOTION_PROPERTIES = `${prefix}/SET_ADDITIONAL_PROMOTION_PROPERTIES`;

export const SET_ARTEFACTS_COUNT = `${prefix}/SET_ARTEFACTS_COUNT`;
export const SET_ARTEFACTS_POSITIONS = `${prefix}/SET_ARTEFACTS_POSITIONS`;
export const CLEAR_ARTEFACTS_POSITIONS = `${prefix}/CLEAR_ARTEFACTS_POSITIONS`;

export const OPEN_PROMO_GAME_POPUP = `${prefix}/OPEN_PROMO_GAME_POPUP`;
export const CLOSE_PROMO_GAME_POPUP = `${prefix}/CLOSE_PROMO_GAME_POPUP`;

export const PROMO_GAME_OPEN_LOGIN_POPUP = `${prefix}/PROMO_GAME_OPEN_LOGIN_POPUP`;
export const PROMO_GAME_CLOSE_LOGIN_POPUP = `${prefix}/PROMO_GAME_CLOSE_LOGIN_POPUP`;

export const PROMO_GAME_CHECKPOINT = `${prefix}/PROMO_GAME_CHECKPOINT`;
export const PROMO_GAME_CHECKPOINT_REPEATED = `${prefix}/PROMO_GAME_CHECKPOINT_REPEATED`;

export const PROMO_GAME_FALSY_ARTEFACT = `${prefix}/PROMO_GAME_FALSY_ARTEFACT`;

export const PROMO_GAME_REWARD_REQUEST_SUCCESS = `${prefix}/PROMO_GAME_REWARD_REQUEST_SUCCESS`;
export const PROMO_GAME_SET_REWARD_STRING = `${prefix}/PROMO_GAME_SET_REWARD_STRING`;

export const PROMO_GAME_FINISH_GAME = `${prefix}/PROMO_GAME_FINISH_GAME`;

export const PROMO_GAME_RESET_GAME = `${prefix}/PROMO_GAME_RESET_GAME`;
