import bat from './bat.svg';
import bat2 from './bat2.png';
import death from './death.svg';
import girl from './girl.png';
import grob from './grob.svg';
import pumpkin from './pumpkin.svg';
import skeleton from './skeleton.svg';

export const artefacts = {
  bat,
  bat2,
  death,
  girl,
  grob,
  pumpkin,
  skeleton,
};
