import * as types from './actionTypes';
import promoGameApi from './api';
import adventApi from '../../../scenes/Advent/api';


export function activateGame(gameName) {
  return {type: types.ACTIVATE_PROMOTION_GAME, payload: {gameName}};
}

export function deactivateGame() {
  return { type: types.DEACTIVATE_PROMOTION_GAME };
}

export function setArtefactsTotalCount(artefactsTotalCount) {
  return {type: types.SET_ARTEFACTS_COUNT, payload: {artefactsTotalCount}}
}

export const clearArtefacts = () => {
  return {type: types.CLEAR_ARTEFACTS_POSITIONS}
}

export const setAdditionalPromoProperties = (payload) => {
  return {type: types.SET_ADDITIONAL_PROMOTION_PROPERTIES, payload}
}

export function setGameArtefactPositions({
                                           catalog,
                                           gameConfig: {
                                             artefactsCountToGenerate = 0,
                                             defaultTruePositions = [],
                                             defaultFalsyPositions = [],
                                             defaultPositionsFalsy,
                                             positionVarints,
                                             picturesCount,
                                             countDefaultInTotal,
                                             categoriesArtefacts,
                                           },
                                          initialApiCatalog = true
                                         }) {
  return dispatch => {
    const additionalArtefactsPositionCount = artefactsCountToGenerate + defaultTruePositions.length

    let filteredProductList = catalog

    if (initialApiCatalog){
      filteredProductList = catalog.reduce((list, cat) => ([...list, ...cat.goods]), [])
    }
    filteredProductList = filteredProductList
      .filter(good => !(good.alias === 'sozday' || good.alias === 'halves'));

    const getRandomArrayIndex = (array = []) => Math.floor(Math.random() * array.length)

    const imageIndexes = [...Array(artefactsCountToGenerate).keys()]

    const createArtefactObject = (id, variants = [], isFalsyArtefact = false, positionVariant, imageSpecificIndex) => {
      const index = getRandomArrayIndex(imageIndexes)

      const artefactObject = {
        id,
        checked: false,
        isFalsyArtefact,
        imageProps: {imageIndex: imageSpecificIndex ?? imageIndexes[index], positionVariant: positionVariant ?? positionVarints[getRandomArrayIndex(positionVarints)]}
        // imageProps: {imageIndex: imageSpecificIndex ?? imageIndexes[index], positionVariant: positionVariant ?? positionVarints[getRandomArrayIndex(positionVariant)]}
      }
      if (variants.length) {
        artefactObject.imageProps.positionVariant = variants[getRandomArrayIndex(variants)]
      }
      imageIndexes.splice(index, 1)
      return artefactObject
    }

    const goodsIndexesToBecomeArtefacts = new Set();

    // УМЫШЛЕННО ПОДГОТАВЛИВАЕМ ИНДЕКСОВ ТОВАРОВ БОЛЬШЕ НЕОБХОДИМОГО (на случай если у дефолтных товаров не будет индексов)
    while (goodsIndexesToBecomeArtefacts.size < additionalArtefactsPositionCount) {
      const newArtefactGoodIndex = getRandomArrayIndex(filteredProductList)
      if (newArtefactGoodIndex
        && filteredProductList[newArtefactGoodIndex]
        && filteredProductList[newArtefactGoodIndex]?.id
      ) {
        goodsIndexesToBecomeArtefacts.add(newArtefactGoodIndex)
      }
    }

    const addImages = [...goodsIndexesToBecomeArtefacts]

    const trueArtefact = [...Array(artefactsCountToGenerate).keys(), ...defaultTruePositions]

    const positions = []

    // ДОБАВЛЯЕМ ПРАВДИВЫЕ АРТЕФАКТЫ
    trueArtefact.forEach((idxOrPos, index) => idxOrPos instanceof Object
      // В СЛУЧАЕ ЕСЛИ У АРТЕФАКТА НЕТ ИНДЕКСА ВОЗЬМЕМ РАНДОМНЫЙ ТОВАР
      ? positions.push(createArtefactObject(idxOrPos.id ?? filteredProductList[addImages[index]].id, idxOrPos.variants, false, idxOrPos.id, idxOrPos.imageIndex))
      : positions.push(createArtefactObject(filteredProductList[addImages[index]].id, positionVarints)))

    // ДОБАВЛЯЕМ ЛОЖНЫЕ АРТЕФАКТЫ
    if (defaultFalsyPositions.length) {
      defaultFalsyPositions.forEach(pos => {
          const falsyArtefact =
            pos instanceof String ? createArtefactObject(pos) : createArtefactObject(pos.id, pos.variants, true, pos.id, pos.imageIndex)
          positions.push(falsyArtefact)
        }
      )
    }

    // ДОБАВЛЯЕМ АРТЕФАКТЫ ДЛЯ КАТЕГОРИЙ
    if (categoriesArtefacts) {
      const categoryAliases = [...new Set(catalog.map(good => good.alias))]
      categoryAliases.forEach(category => positions.push(createArtefactObject(category, [], categoriesArtefacts === 'falsy', category)))
    }

    const realArtefactsCount = positions.filter(art => !art.isFalsyArtefact).length

    dispatch(setArtefactsTotalCount(realArtefactsCount))
    dispatch({type: types.SET_ARTEFACTS_POSITIONS, payload: {positions}})
  }
}

export function openGamePopup() {
  return {type: types.OPEN_PROMO_GAME_POPUP};
}

export function closeGamePopup() {
  return {type: types.CLOSE_PROMO_GAME_POPUP};
}

export function closeGamePopupLogin() {
  return {type: types.PROMO_GAME_CLOSE_LOGIN_POPUP};
}

export function gameCheckpoint(id) {
  return (dispatch, getState) => {

    const promoGameProperties = getState().promotionGame
    const { isAuthenticated } = getState().user
    const isAlreadyChecked = promoGameProperties.artefacts.find(art => art.id === id).checked
    const {artefactsCheckedCount, artefactsTotalCount, rewardCode, successAction, onlyForRegisteredUsers, isPromoProgressFinished} = promoGameProperties.common

    if (onlyForRegisteredUsers && !isAuthenticated) {
      dispatch({type: types.PROMO_GAME_OPEN_LOGIN_POPUP});
      return;
    }
    dispatch(openGamePopup())

    if (isPromoProgressFinished) {
      return;
    }

    if (!isAlreadyChecked) {
      dispatch({type: types.PROMO_GAME_CHECKPOINT, payload: {id}});
    } else {
      dispatch({type: types.PROMO_GAME_CHECKPOINT_REPEATED});
      if (artefactsCheckedCount >= artefactsTotalCount) {
        dispatch(finishPromoAction())
      }
      return
    }
    if (artefactsCheckedCount + 1 >= artefactsTotalCount) {
      dispatch(finishPromoAction())
    }
  }
}

function finishPromoAction() {
  return async (dispatch, getState) => {
    const promoGameProperties = getState().promotionGame

    const { rewardCode, successAction: {type, festive}, rewardString} = promoGameProperties.common

    if (type === 'coupon' && !rewardCode){
      dispatch(getPromoCoupon())
    }
    if (type === 'festive' && festive) {
      try {
        await adventApi.taskCheckpoint(festive.taskId)
        dispatch({type: types.PROMO_GAME_FINISH_GAME})
      } catch (e) {
        throw e
      }
    }

  }
}

export const checkActiveFestive = () => async (dispatch, getState) => {
  try {
    const { common } = getState().promotionGame;

    const { successAction } = common
    const { festive } = successAction

    if (!festive || !festive.id) {
      return;
    }

    const tasks = await adventApi.getAdventTasks(festive.id);

    if (!Array.isArray(tasks)) {
      return;
    }

    const activeTask = tasks.find((item) => item.status === 'active');

    if (!activeTask) {
      dispatch(setAdditionalPromoProperties(
        {
          ...common,
          artefactsCheckedCount: common.artefactsTotalCount,
          isPromoProgressFinished: true,
          rewardString:tasks.findLast(t => t)?.description ?? '',
          successAction: {...successAction,
            festive: {id: festive.id, taskId:null}}
        }))
    } else {
      dispatch(setAdditionalPromoProperties({
        ...common,
        isPromoProgressFinished: false,
        rewardString: activeTask.description,
        successAction: {
          ...successAction,
          festive: {id: festive.id, taskId:activeTask.id}
        }
      }))
      dispatch(resetPromoGame())
    }

  } catch (error) {
    console.error('Ошибка при проверке активного праздника:', error);
  }
};

const resetPromoGame = () => {
  return {type: types.PROMO_GAME_RESET_GAME};
}

export const setPromoReward = (reward) => {
  return {type: types.PROMO_GAME_SET_REWARD_STRING, payload: {reward}};
}

export function gameFalsyCheckpoint() {
  return (dispatch, getState) => {
    dispatch({type: types.PROMO_GAME_FALSY_ARTEFACT})

    dispatch(openGamePopup())
  }
}

export const getPromoCoupon = () => dispatch => {

  promoGameApi.getPromoCoupon()
    .then(data => dispatch(promoGameFinished(data.coupon)))
    .catch(() => {
      dispatch(closeGamePopup())
    })
}

export const promoGameFinished = (rewardCode) => {
  return {type: types.PROMO_GAME_REWARD_REQUEST_SUCCESS, payload: {rewardCode}}
}
