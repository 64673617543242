import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import Modal3 from '../Modal3/Modal';
import Modal from '../Modal/Modal';

import styles from './ModalGeneral.styl';

// Icons
import CrossIcon from '../../icons/cross.svg';

const cx = classNames.bind(styles);

const ModalGeneral = ({
  isOpen,
  onClose,
  className = '',
  withCross = false,
  children,
  decor = null,
}) => {
  const isRealPhone = useSelector(state => state.responsive.isRealPhone);

  const ModalComp = useRef(isRealPhone ? Modal3 : Modal).current;

  return (
    <ModalComp
      className={cx(
        styles.ModalBlock,
        { ModalBlock_desktop: !isRealPhone },
        { ModalBlock_withLogo: !!decor },
        className,
      )}
      direction="top"
      isOpen={isOpen}
      onClose={onClose}
    >
      {decor}
      <div className={cx(styles.ModalBlock__content)}>
        {children}
        {withCross && <CrossIcon onClick={onClose} className={cx(styles.ModalBlock__cross)} />}
      </div>
    </ModalComp>
  );
};

export default ModalGeneral;

ModalGeneral.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  decor: PropTypes.node,
  withCross: PropTypes.bool,
};
