import React from 'react';
import { useIntl } from 'react-intl';

import ModalGeneral from '../ModalGeneral/ModalGeneral';

import styles from './AdultModal.styl';
import Button from '../Button';
import SmileOppositeIcon from '../../icons/smile_oposite.svg';
import Heading from '../Heading';
import { useDispatch, useSelector } from 'react-redux';
import { disableAdultGoods, toggleAdultGoodsPopup } from '../../state/modules/ui/actions';
import isomorphicCookies from '../../utils/cookie/isomorphicCookies';
import { ADULT_GOODS_ALLOWED_COOKIE_NAME } from '../../utils/constants';


export const AdultModal = () => {

  const intl = useIntl();
  const dispatch = useDispatch();

  const { isAdultGoodsModalShown } = useSelector(state => state.ui);

  const onSuccess = () => {
    dispatch(disableAdultGoods());
    dispatch(toggleAdultGoodsPopup());
    isomorphicCookies().set(ADULT_GOODS_ALLOWED_COOKIE_NAME, 1, {expires: 99999})
  };

  const onReject = () => {
    dispatch(toggleAdultGoodsPopup());
  };
  return (
    <ModalGeneral className={styles.AdultModal} isOpen={isAdultGoodsModalShown} onClose={onReject}>
      <div className={styles.AdultModal__wrapper}>
        <SmileOppositeIcon width={46} height={46} />
        <Heading level={3}
                 className={styles.AdultModal__title}>{intl.formatMessage({ id: 'adult.modal.title' })}</Heading>
        <Heading level={4}
                 className={styles.AdultModal__subtitle}>{intl.formatMessage({ id: 'adult.modal.subtitle' })}</Heading>
        <div className={styles.AdultModal__buttons}>
          <Button onClick={onSuccess}>{intl.formatMessage({ id: 'adult.modal.yes' })}</Button>
          <Button secondary onClick={onReject}>{intl.formatMessage({ id: 'adult.modal.no' })}</Button>


        </div>
      </div>
    </ModalGeneral>
  );
};

