import config from '../config';

export const CRUST_EXTRA = 'Корочка';
export const CRUST_EXTRA_EN = 'Crust';
export const MOZZARELLA = 'сыр моцарелла';
export const MOZZARELLA_EN = 'mozzarella cheese';
export const timeZones = [
  { id: 'Europe/Kaliningrad', value: 'Калининград' },
  { id: 'Europe/Moscow', value: 'Москва' },
  { id: 'Europe/Samara', value: 'Самара' },
  { id: 'Asia/Yekaterinburg', value: 'Екатеринбург' },
  { id: 'Asia/Omsk', value: 'Омск' },
  { id: 'Asia/Krasnoyarsk', value: 'Красноярск' },
  { id: 'Asia/Irkutsk', value: 'Иркутск' },
  { id: 'Asia/Yakutsk', value: 'Якутск' },
  { id: 'Asia/Vladivostok', value: 'Владивосток' },
  { id: 'Asia/Magadan', value: 'Магадан' }
];

export const payloadTooLarge = (locale) => {
  const lang = config.legal === 'kz' || config.legal === 'kg' ? 'en' : locale;
  const messageOptions = {
    ru: 'Слишком большой размер файла',
    en: 'The attachment is too large'
  };
  return messageOptions[lang];
};
export const unprocessableEntity = (locale) => {
  const lang = config.legal === 'kz' || config.legal === 'kg' ? 'en' : locale;
  const messageOptions = {
    ru: 'Неверный тип изображения',
    en: 'Invalid image type'
  };
  return messageOptions[lang];
};

export const closedCities = [ "tolyatti", "ulyanovsk" ];

export const ADULT_GOODS_ALLOWED_COOKIE_NAME = 'al_ad_g_c'

