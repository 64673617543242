import { get, post } from '../../utils/api';

const adventApi = {
  getAdventTasks: eventId =>
    get({ url: `festive/task-list`, data: { eventId } }).then(data => data),
  taskCheckpoint: id => post({ url: 'festive/task-confirm', data: { task_id: id } }),
  addNotification: data => post({ url: 'festive/contact', data }),
};

export default adventApi;
